import { LinearProgress as MuiLinearProgress } from "@mui/material";


export function LinearProgress(props) {
  return (
    <MuiLinearProgress {...props} sx={{
      height: 12,
      borderRadius: 6,
      '& .MuiLinearProgress-bar': {
        borderRadius: 6,
        '&::after': {
          content: `""`,
          position: 'absolute',
          top: 2,
          right: 8,
          width: `calc(${props.value}% - 16px)`,
          backgroundColor: 'rgba(255,255,255,20%)',// 'black',
          height: 3,
          borderRadius: 1.5,
        },
      },
      ...props?.sx
    }} />
  );
}

LinearProgress.propTypes = {
  ...MuiLinearProgress.propTypes,
};
