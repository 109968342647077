import { FC, SyntheticEvent, memo } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { GroupColor } from '../mui';
import { Comment as CommentType, Contact } from '../generated/graphql';
import { useSession } from '../hooks/auth';
import { User } from './User';
import { FollowButton } from './FollowButton';
import { Avatar } from './Avatar';
import { DecoratedText, DecoratedTextProps } from './DecoratedText';
import { CommentLike, CommentLikeProps } from './Post/CommentLike';


export type CommentProps = {
  share?: CommentLikeProps['share'],
  comment: Pick<CommentType, '_id' | 'text' | 'language'> & {
    author?: Pick<Contact, '_id'> | null,
    textLinks?: DecoratedTextProps['links'] | null,
    mentions?: DecoratedTextProps['mentions'] | null,
  } & CommentLikeProps['comment'],
  contextColor?: GroupColor | string,
  elevate?: boolean,
  showAuthor?: boolean,
  onClick?: (e: SyntheticEvent) => void,
};

export const Comment: FC<CommentProps> = memo(function Comment({ share, comment, contextColor, elevate=false, showAuthor=true, onClick, ...rest }: CommentProps) {

  const theme = useTheme();
  const { user } = useSession();

  const userIsAuthor = user && user._id === comment.author?._id;

  let backgroundColor: string = '';
  if(userIsAuthor && contextColor) {
    if(theme.palette.groups[contextColor as GroupColor]?.background) {
      backgroundColor = theme.palette.groups[contextColor as GroupColor].background;
    } else {
      backgroundColor = contextColor;
    }
  } else {
    backgroundColor = theme.palette.grey[100];
  }

  if(!comment?.author) {
    showAuthor = false;
  }

  return (
    <Box sx={{
        display: 'flex',
        mx: 2
      }}
      {...rest}
    >
      {!userIsAuthor && comment.author &&
        <Avatar
          sx={{ mr: 1, mt: 1.5 }}
          size={'small'}
          contact={comment.author}
        />
      }
      <Box sx={{ display: 'flex', justifyContent: userIsAuthor ? 'flex-end' : 'flex-start', width: '100%' }}>
        <Box
          sx={{
            px: 1,
            py: '6px',
            borderRadius: '4px',
            mt: 0.5,
            maxWidth: '80%',
            minWidth: '10%',
            backgroundColor: backgroundColor,
            border: '1px solid',
            borderColor: theme.palette.grey[300],
            boxShadow: elevate ? theme.shadows[1] : 'none',
            '&:first-of-type': {
              mt: 1
            },
            alignSelf: userIsAuthor ? 'flex-end' : 'flex-start',
            width: 'fit-content',
            overflowWrap: 'break-word',
            position: 'relative',
            order: userIsAuthor ? 1 : -1, // place like-heart on the left side of own comments
          }}
          onClick={ onClick || (() => {}) }
          data-cy='post-comment'
          lang={comment.language}
        >
          {(showAuthor && !userIsAuthor && comment.author) &&
            <Box sx={{ mb: '2px', display: 'flex' }}>
              <User userId={comment.author._id} avatar={false} size='small' sx={{ typography: { color: theme.palette.text.secondary, fontSize: 16 } }} />
              <FollowButton contact={comment.author} fontSize='14px' lineHeight='20px' />
            </Box>
          }
          <DecoratedText text={comment.text} links={comment.textLinks} mentions={comment.mentions} />

        </Box>
        {share &&
          <CommentLike share={share} comment={comment} />
        }
      </Box>
    </Box>
  );
});
