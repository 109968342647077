import { FC, ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

import { useContact } from '../hooks/use-contact';


export type UserNameProps = {
  userId: string,
  addLink?: boolean,
  postfix?: ReactNode,
} & TypographyProps


export const UserName: FC<UserNameProps> = function UserName({ userId, addLink = true, postfix = '', ...rest }) {

  const history = useHistory();
  const { data, loading, error } = useContact(userId);

  if(loading) {
    return null;
  }

  if(error) {
    console.error("Couldn't resolve contact in User", { userId, error });
    return null;
  }

  const user = data?.contact;

  if(!user) {
    console.warn("Couldn't resolve contact for UserName", { userId });
    return (
      <Typography variant={'h4'} noWrap {...rest}>
        User not found
      </Typography>
    );
  }

  const userComponent = (
    <>
      <Typography variant={'h4'} noWrap {...rest}>
        {user.username}{postfix}
      </Typography>
    </>
  );

  if (addLink) {
    return (
      <Link
        style={{ display: 'flex', alignItems: 'center', margin: '-8px 0', padding: '8px 0' }}
        to={`/@${user.username}`}
        onClick={ev => {
          ev.stopPropagation();
          ev.preventDefault();
          history.push(`/@${user.username}`);
        }}
      >
        {userComponent}
      </Link>
    );
  }

  return userComponent;
}
