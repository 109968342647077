import { FC, SyntheticEvent, useState } from 'react';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MoreHorizontal } from 'react-feather';

import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useSession } from '../../hooks/auth';
import { useLoginPush } from '../../hooks/login-push';
import { MoreSheet, MoreSheetProps } from './MoreSheet';


export type MoreButtonProps = {
  share: MoreSheetProps['share'],
}


export const MoreButton: FC<MoreButtonProps> = function MoreButton({ share }) {

  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();
  const [sheetOpen, setSheetOpen] = useState(false);

  const onClick = (ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(user) {
      setSheetOpen(true);
      track('open_more_sheet', { category: 'more', origin: 'post' });
    } else {
      track('click_more_button', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to interact with this post.');
    }
  };

  const onClose = (ev?: SyntheticEvent) => {
    if(ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    setSheetOpen(false);
  };

  return (
    <>
      <IconButton aria-label="more options" onClick={onClick} size="large">
        <MoreHorizontal
          style={{
            stroke: theme.palette.text.secondary,
          }}
          width={20}
          height={20}
        />
      </IconButton>
      {sheetOpen &&
        <MoreSheet share={share} onClose={onClose} variant='all' />
      }
    </>
  );
}
