import { FC, SyntheticEvent } from 'react';
import { Card, Box, Typography, SxProps } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { Bookmark } from 'react-feather';
import { useHistory } from 'react-router-dom';

import { Contact, Image as ImageType, List } from '../../generated/graphql';
import { Image } from '../Image';
import { User } from '../User';


export type ListShareProps = {
  list: Pick<List, 'name' | 'description'> & {
    createdBy?: Pick<Contact, '_id'> | null,
    images: Pick<ImageType, 'hash' | 'width' | 'height' | 'alt' | 'blurhash'>[],
  },
  sx: SxProps,
  link: string,
  showUser: boolean
};


export const ListShare: FC<ListShareProps> = function ListShare({ list, sx, link, showUser = true }) {

  const theme = useTheme();
  const history = useHistory();

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if(link) {
      history.push(link);
    }
  };

  return (
    <>
      <Card
        sx={{
          position: 'relative',
          zIndex: 3,

          backgroundColor: 'grey.100',
          borderColor: 'grey.300',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 0.5,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',

          ...sx
        }}
        onClick={onClick}
      >

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Bookmark style={{
            display: 'block',
            width: 16,
            height: 16,
            stroke: theme.palette.primary.main,
            fill: theme.palette.primary.main,
            flex: '0 0 auto',
          }} />
          <Typography variant='h4' noWrap sx={{ pr: 0.5, flex: '1 1 100px' }}>
            {list.name}
          </Typography>
          {list.createdBy?._id && showUser &&
            <User userId={list.createdBy._id} size={'small'} addLink={false} sx={{ flex: '0 1 auto' }} postfix='' />
          }
        </Box>

        <Typography variant='body1' sx={{ mt: 1, flex: '1 1 auto', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, lineClamp: 3, textOverflow: 'ellipsis', overflowWrap: 'break-word' }}>
          {list.description}
        </Typography>

        <Box sx={{
          p: 0.5,
          display: 'flex',
          gap: 1,
          mt: 1,
          overflow: 'hidden',
          width: 'fit-content',
          position: 'relative',
        }}>
          {list.images.map(image => (
            <Box key={`${image.hash}`} sx={{ width: 40, height: 60, borderRadius: 0.5, overflow: 'hidden', flex: '0 0 auto', '&:last-child::after': {
              content: '""',
              position: 'absolute',
              zIndex: 1,
              top: 0,
              right: 0,
              pointerEvents: 'none',
              backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.grey[100], 0)}, ${alpha(theme.palette.grey[100], 1)} 90%)`,
              height: '100%',
              width: '30px',
            } }}>
              <Image
                {...image}
                constraints={`${80}x${120}_c`}
                width={40}
                height={60}
              />
            </Box>
          ))}
        </Box>

      </Card>

    </>
  );
}
