import { CSSProperties, FC, ReactNode, useCallback, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { useAnalyticsQueued } from "../hooks/delicious-analytics";


export type AdTrackerProps = {
  children: ReactNode,
  type: 'banner' | 'native' | 'interstitial' | 'canonical' | 'profile',
  position: string,
  id: string,
  account: string,
  campaign: string,
  trackingPixel?: string,
  clickCounter: number,
  style?: CSSProperties,
};

export const AdTracker: FC<AdTrackerProps> = function AdTracker({ children, type, position, id, account, campaign, trackingPixel, clickCounter, style }) {

  const { track } = useAnalyticsQueued();
  const [hasBeenViewed, setHasBeenViewed] = useState(false);

  useEffect(() => {
    if(clickCounter > 0) {
      // console.log('AdTracker clickCounter', clickCounter, type, position, id, account, campaign);
      track('ad_click', { category: 'ad', type, position, ad_id: id, account, campaign });
    }
  }, [track, type, position, id, account, campaign, clickCounter]);

  const handleViewChange = useCallback((inView: boolean, _entry: IntersectionObserverEntry ) => {
    // console.log('AdTracker InView', { inView, _entry, type, position, id, account, campaign });
    if(inView) {
      track('ad_impression', { category: 'ad', type, position, ad_id: id, account, campaign });
      setHasBeenViewed(true);
    }
  }, [track, type, position, id, account, campaign]);

  return (
    <InView threshold={0.95} trackVisibility={true} delay={200} triggerOnce={true} onChange={handleViewChange}>
      <div style={style}>
        {children}
        {trackingPixel && hasBeenViewed &&
          <img src={trackingPixel} alt="" width="1" height="1" style={{ display: 'none' }} />
        }
      </div>
    </InView>
  );
}
