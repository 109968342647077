import { useState } from 'react';
import PropTypes from 'prop-types';
import { Clipboard } from '@capacitor/clipboard';
import { Box } from '@mui/material';

import { Dialog } from './Dialog';
import { Button } from './Button';


export function CopyInviteLink({ invitationLink, group=null, onClose }) {

  const [buttonText, setButtonText] = useState('Copy');

  const handleCopy = () => {
    Clipboard.write({
      url: invitationLink
    }).then(() => {
      setButtonText('Copied');
      setTimeout(() => setButtonText('Copy'), 4000);
    }).catch(err => {
      console.error(err);
    });
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      actionText='Close'
      onAction={onClose}
      title='Almost there'
      content={
        <>
          Send the following link to your friends for them to join {group ? group.name : 'you'} on Ice&nbsp;Cream&nbsp;Club.

          <Box
            sx={{
              display: 'flex',
              backgroundColor: 'offWhite.light',
              color: 'text.primary',
              border: '1px solid black',
              p: 1,
              mt: 2,
            }}
            data-cy="invite-link"
          >
            <code style={{
              userSelect: 'all',
              display: 'block',
              overflow: 'auto',
              whiteSpace: 'nowrap',
              flex: '1',
            }}>
              {invitationLink}
            </code>
            <Button variant="text" onClick={handleCopy} sx={{ flex: '0', margin: '-12px 0' }}>
              {buttonText}
            </Button>
          </Box>
        </>
      }
    />
  );
}

CopyInviteLink.propTypes = {
  invitationLink: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  group: PropTypes.object,
}
