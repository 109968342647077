import { FC, SyntheticEvent } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Box, Collapse, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Contact, useGetFollowsQuery } from '../generated/graphql';
import { useFollowings } from '../hooks/following';
import { useSession } from '../hooks/auth';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { useLoginPush } from '../hooks/login-push';


interface FollowButtonProps {
  contact: Pick<Contact, '_id'>,
  lineHeight?: string,
  fontSize?: string,
  divider?: boolean,
  onFollow?: (contact: Pick<Contact, '_id'>) => void,
}

export const FollowButton: FC<FollowButtonProps> = function FollowButton({ contact, lineHeight, fontSize, divider=true, onFollow }) {
  const theme = useTheme();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();

  const { follow } = useFollowings();
  const { data, loading, error } = useGetFollowsQuery({ skip: !user });
  const followed = !!( contact._id === data?.currentUser?._id || data?.currentUser?.follows?.some(f => f._id === contact._id) );

  const handleClick = function(ev: SyntheticEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    if(user) {
      follow(contact);
      if(onFollow) {
        onFollow(contact);
      }
    } else {
      track('click_follow', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in to follow users.');
    }
  }

  return (
    <TransitionGroup>
      {!loading && !error && !followed &&
        <Collapse orientation='horizontal'>
          <Box sx={{ display: 'flex', margin: '-8px', padding: '8px' /* margin/padding hack to enlarge clickable area */ }} onClick={handleClick}>
            {divider &&
              <Typography variant="button" sx={{ mx: '6px', lineHeight, fontSize }}>•</Typography>
            }
            <Typography variant='button' sx={{ color: theme.palette.primary.main, lineHeight, fontSize }}>Follow</Typography>
          </Box>
        </Collapse>
      }
    </TransitionGroup>
  );
}
