import PropTypes from 'prop-types';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog, DialogActions, DialogContent, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Trash } from 'react-feather';

import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { DELETE_SHARE } from '../../queries/share';
import { Button } from '../Button';


export function DeleteButton({ shareId, onDelete }) {

  const theme = useTheme();
  const { track } = useAnalyticsQueued();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [deleteShare, { error }] = useMutation(DELETE_SHARE);

  if(error) {
    console.error(error);
  }

  return (
    <ListItem sx={{ px: 0 }}>
      <Button
        variant='contained'
        size='small'
        fullWidth
        sx={{ backgroundColor: theme.palette.grey[300], color: theme.palette.text.primary }}
        onClick={() => setConfirmOpen(true)}
        data-cy="delete-post"
      >
        <Trash style={{ marginRight: 4, width: 16, height: 16 }} />
        Delete
      </Button>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
      >
        <DialogContent>
          <Typography variant='body1' paragraph>
            Are you sure you want to delete this post?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={() => {
              track(`delete_post`, { category: 'post' });
              deleteShare({ variables: { id: shareId } });
              setConfirmOpen(false);
              onDelete();
            }}
          >
            Delete it!
          </Button>
          <Button variant='text' onClick={() => setConfirmOpen(false)} sx={{ color: theme.palette.text.primary }}>
            Not right now
          </Button>
        </DialogActions>
      </Dialog>
    </ListItem>
  );
}
DeleteButton.propTypes = {
  shareId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};
