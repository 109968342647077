import { useState, useEffect, FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import * as EmailValidator from 'email-validator';

import { useTranslation } from '@/utils/i18n';
import { useAnalyticsQueued } from '@/hooks/delicious-analytics';


export type EmailInputProps = {
  email: string,
  setEmail: (email: string) => void,
} & TextFieldProps;


export const EmailInput: FC<EmailInputProps> = function EmailInput({ email, setEmail, type = 'email', ...props }) {

  const { track } = useAnalyticsQueued();
  const { t } = useTranslation('auth');

  const [ focus, setFocus ] = useState<boolean>(false);
  const [ helper, setHelper ] = useState<string>();

  useEffect(() => {
    if (email && !focus && !EmailValidator.validate(email)) {
      setHelper('Invalid email');
    } else if (!email || EmailValidator.validate(email)) {
      setHelper('');
    }
  }, [ focus, email ]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(ev.target.value && !email) {
      track('auth_email_input', { category: 'auth', action: 'start_typing' });
    }
    setEmail(ev.target.value);
  }

  const handleFocus = () => {
    if(!focus) {
      track('auth_email_input', { category: 'auth', action: 'focus' });
    }
    setFocus(true);
  }

  return (
    <TextField
      error={!!helper}
      helperText={helper}
      id="email"
      placeholder={t('email')}
      type={type}
      fullWidth
      margin="normal"
      variant="filled"
      size="small"
      value={email}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={ () => setFocus(false) }
      data-cy='login-email'
      autoComplete="username" // This is important for password managers
      sx={{
        marginTop: 0,
        height: '47px', // This is weird, but prevents flickering on ios/safari
        'input': {
          '&::placeholder': {
            color: 'text.secondary',
            opacity: 1,
          }
        },
        display: type === 'hidden' ? 'none' : 'block'
      }}
      {...props}
    />
  );
}
