import { FC, memo } from "react";
import * as languageParser from 'accept-language-parser';

import { CanonicalContent, Movie, TvShow } from "@/generated/graphql";
import { useSession } from "@/hooks/auth";


export type TitleProps = {
  canonical: Pick<CanonicalContent, 'type'> & {
    movie?: Pick<Movie, 'title' | 'originalTitle' | 'originalLanguage'> | null,
    tvshow?: Pick<TvShow, 'title' | 'originalTitle' | 'originalLanguage'> | null,
  },
};


export const Title: FC<TitleProps> = memo(function Title({ canonical }: TitleProps) {

  const { user } = useSession();

  const content = canonical[canonical.type === 'movie' ? 'movie' : 'tvshow'];
  if(!content) {
    console.error('No content provided to Title component', canonical);
    return null;
  }
  const { title, originalLanguage, originalTitle } = content;

  // console.log('Title', title, originalTitle, originalLanguage, user?.language);

  if(title !== originalTitle && originalTitle) {
    if(user?.language && originalLanguage) {
      if(languageParser.pick([originalLanguage], user.language)) {
        return originalTitle;
      }
    }
  }
  return title;
});
