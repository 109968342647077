import { FC, useCallback, useEffect, useState } from "react";
import { Capacitor } from "@capacitor/core";
import { AppTrackingTransparency, AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';
import { Box, Container, Dialog, Typography } from "@mui/material";
import { FacebookTracking } from 'capacitor-facebook-tracking';

import { useAnalyticsQueued } from "../hooks/delicious-analytics";
import { Button } from "./Button";
import { Link } from "./Link";


export const AppTrackingPrompt: FC = function AppTrackingPrompt() {

  const platform = Capacitor.getPlatform();
  const { track } = useAnalyticsQueued();

  const [response, setResponse] = useState<AppTrackingStatusResponse | Error | null>(null);

  useEffect(() => {
    if(response === null && platform === 'ios') {
      track('att_prompt_init', { category: 'app_tracking' });

      AppTrackingTransparency.getStatus().then((response) => {
        track(`att_status_${response.status}`, { category: 'app_tracking' });
        setResponse(response);
      }).catch((error) => {
        console.error(`Error getting ATT status`, error);
        track(`att_status_error`, { category: 'app_tracking' });
        setResponse(error);
      });
    } else {
      // ATT status already requested or not on iOS
    }
  }, [platform, response, track]);

  const requestPermission = useCallback(() => {
    track(`att_request_permisson`, { category: 'app_tracking' });
    AppTrackingTransparency.requestPermission().then((response) => {
      track(`att_status_${response.status}`, { category: 'app_tracking' });
      const trackingAllowed = response.status === 'authorized' ? true : false;

      track(`att_tracking_${trackingAllowed ? 'enabled' : 'disabled'}`, { category: 'app_tracking' });

      FacebookTracking.setAdvertiserTrackingEnabled({ enabled: trackingAllowed });
      FacebookTracking.setAutoLogAppEventsEnabled({ enabled: trackingAllowed });
      FacebookTracking.setAdvertiserIDCollectionEnabled({ enabled: trackingAllowed });
      setResponse(response);
    }).catch((error) => {
      console.error(`Error requesting ATT permissions`, error);
      track(`att_permission_error`, { category: 'app_tracking' });
      setResponse(error);
    });
  }, [track]);


  if(!response || !('status' in response) || response.status !== 'notDetermined') {
    return null;
  }

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={requestPermission}
    >
      <Container maxWidth="sm" disableGutters sx={{ pt: 'var(--sat)' }}>
        <Box sx={{ px: 4, pt: 7 }}>
          <Typography variant="h2" gutterBottom>
            Get the most out of your experience with Ice Cream Club
          </Typography>
          <Typography variant="body1" gutterBottom>
            We need your permission to use your data to provide and improve the service.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Tap {"'Allow'"} on the next screen to enable the us to give you a more personalized experience.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You can find our privacy policy at <Link link="https://icecream.club/legal/privacy">icecream.club/privacy</Link>.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={requestPermission}
            fullWidth
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
}
