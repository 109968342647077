import { FC, SyntheticEvent } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { format } from "date-fns";

import { Season } from "../../generated/graphql";
import { Button } from "../Button";
import { CheckCircle, CheckCircleFilled } from "../icons";
import { useCheckIsWatched } from "../../hooks/watch-state";


export type WatchedSeasonsDialogProps = {
  canonicalId: string,
  open: boolean,
  seasons: Pick<Season, 'seasonNumber' | 'name' | 'airDate'>[],
  onClose: () => void,
  handleWatchedSeason: (ev: SyntheticEvent, seasonNumbers: number[], active: boolean, createFeedItems: boolean) => void,
};


export const WatchedSeasonsDialog: FC<WatchedSeasonsDialogProps> = function WatchedSeasonsDialog({ canonicalId, open, seasons, onClose, handleWatchedSeason }) {

  const checkIsWatched = useCheckIsWatched();

  const watchedSeasons = seasons.map(season => season.seasonNumber).filter(seasonNumber => checkIsWatched(undefined, canonicalId, seasonNumber));

  function seenAll(ev: SyntheticEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    handleWatchedSeason(ev, seasons.map(season => season.seasonNumber), true, true);
  }

  function seenNone(ev: SyntheticEvent) {
    ev.stopPropagation();
    ev.preventDefault();
    handleWatchedSeason(ev, seasons.map(season => season.seasonNumber), false, false);
  }

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { minWidth: 250 } }}>
      <DialogTitle variant="h3" sx={{ pb: 0 }}>
        Seen seasons
      </DialogTitle>

      <DialogContent>
        <List>
          {seasons.map((season) => {
            const watched = watchedSeasons.includes(season.seasonNumber);
            return (
              <ListItem role={undefined} key={season.seasonNumber} onClick={(ev: SyntheticEvent) => handleWatchedSeason(ev, [season.seasonNumber], !watched, true)} dense disableGutters>
                <ListItemIcon sx={{ minWidth: 24 }}>
                  <Box sx={{ typography: 'body2', my: '6px', minWidth: 'auto', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                    {watched ?
                      (<CheckCircleFilled sx={{ width: 16, height: 16, color: 'primary.main' }} />) :
                      (<CheckCircle sx={{ width: 16, height: 16, color: 'grey.400', strokeWidth: 2 }} />)
                    }
                  </Box>
                </ListItemIcon>
                <ListItemText id={season.seasonNumber.toString()} primary={`${season.name} (${format(new Date(season.airDate), 'yyyy')})`} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>

      <DialogActions>
        {watchedSeasons.length === seasons.length &&
          <Button onClick={seenNone} color="primary" variant="text" sx={{ color: 'text.secondary' }}>
            Seen none
          </Button>
        }
        {watchedSeasons.length !== seasons.length &&
          <Button onClick={seenAll} color="primary" variant="text" sx={{ color: 'text.secondary' }}>
            Seen all
          </Button>
        }

        <Button onClick={(ev) => { ev.preventDefault(); ev.stopPropagation(); onClose(); }} color="primary" variant="text">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
