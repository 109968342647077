import { FC, useEffect, useState } from "react";
import { TabPanel, TabPanelProps } from "./TabPanel";


export const LazyTabPanel: FC<TabPanelProps> = function LazyTabPanel({ index, value, children, ...rest }) {

  const active = value === index;
  const [loaded, setLoaded] = useState(active);

  useEffect(() => {
    if(active && !loaded) {
      setLoaded(true);
    }
  }, [active, loaded]);

  return (
    <TabPanel value={value} index={index} {...rest}>
      {(active || loaded) &&
        <>
          {children}
        </>
      }
    </TabPanel>
  );
}
