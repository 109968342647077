import { FC, forwardRef } from 'react';
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, DialogContent, Typography, Box, Button, ButtonProps, TypographyProps, Slide, SlideProps } from '@mui/material';


type DialogProps = MuiDialogProps & {
  onAction?: () => void;
  content?: React.ReactNode;
  actionText?: string;
  actionDisabled?: boolean;
  abortText?: string;
  title?: string;
  titleProps?: TypographyProps;
  actionButtonProps?: ButtonProps;
};

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>;
});

export const Dialog: FC<DialogProps> = function Dialog({ onAction, onClose, open, title, content, actionText, actionDisabled=false, abortText, titleProps={}, actionButtonProps={}, children, ...rest }) {

  const mt = '48px';
  const mb = '48px';
  const ml = '16px';
  const mr = '16px';

  return (
    <MuiDialog
      onClose={onClose}
      open={open}
      fullWidth={true}
      scroll='paper'
      TransitionComponent={Transition}
      {...rest}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: 1,
          backgroundColor: 'grey.100',
          mt: `calc(${mt} + var(--sat))`,
          mb: `calc(${mb} + var(--sab))`,
          ml: `calc(${ml} + var(--sal))`,
          mr: `calc(${mr} + var(--sar))`,
          width: `calc(100% - ${ml} - ${mr} - var(--sal) - var(--sar))`,
          maxHeight: `calc(100% - ${mt} - ${mb} - var(--sat) - var(--sab))`,
        },
        ...rest.sx,
      }}
    >
      {(content || title) &&
        <DialogContent>
          {/*
          We don't use DialogTitle and DialogActions because it
          produces unwanted scrolling and overflow behavior
          */}
          {title &&
            <Typography variant='h3' {...titleProps} sx={{ paddingTop: 1, paddingBottom: 2, ...titleProps.sx }}>
              {title}
            </Typography>
          }

          {content}

          {(actionText || abortText) &&
            <Box sx={{
              paddingTop: 3,
              flexDirection: 'column',

              '& > :not(:first-of-type)': {
                marginLeft: 0,
              },
            }}>
              {actionText &&
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  onClick={onAction}
                  disabled={actionDisabled}
                  data-cy='dialog-action'
                  {...actionButtonProps}
                >
                  {actionText}
                </Button>
              }
              {abortText &&
                <Button
                  variant="text"
                  fullWidth
                  color="secondary"
                  onClick={(ev) => onClose && onClose(ev, 'backdropClick')}
                  sx={{ marginTop: 1, boxShadow: "none", mb: 0 }}
                  data-cy='dialog-abort'
                >
                  {abortText}
                </Button>
              }
            </Box>
          }

        </DialogContent>
      }
      {children}
    </MuiDialog>
  );
}
