import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FallbackImage as Icon } from './icons/FallbackImage';

export function FallbackImage ({ color, scale, style }) {

  const aspect = Math.max(275/213);

  return (
    <Box style={{ paddingTop: 1/aspect*100 + '%', position: 'relative', width: '100%', ...style }}>
      <Icon
        style={{
          position: 'absolute',
          width: '100%',
          height:'100%',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
        color={color}
        scale={scale}
      />
    </Box>
  );
}

FallbackImage.propTypes = {
  color: PropTypes.string.isRequired,
  scale: PropTypes.number,
  style: PropTypes.object,
}
