import { FC } from 'react';
import { Container, Typography } from '@mui/material';
import { Route, useHistory } from 'react-router-dom';
import { Button } from './Button';
import { ErrorLogo, ErrorIceCream } from './icons';


type NotFoundProps = {
  message?: string;
};


export const NotFound: FC<NotFoundProps> = function NotFound({ message }) {
  const history = useHistory();
  const reload = () => {
    history.go(0);
  };

  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) {
          staticContext.statusCode = 404;
        }
        return (
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              height: '100%',
              backgroundColor: '#FAADA8',
              position: 'relative',
              padding: '64px 32px',
            }}
            maxWidth="sm"
          >
            <ErrorLogo sx={{ width: '110px', height: '50px' }} />
            <Typography variant="h2" style={{
              marginTop: 96,
              marginBottom: 16,
              whiteSpace: 'pre-line',
              lineHeight: 1.4,
              fontSize: '36px',
              color: '#C3362B',
              textAlign: 'center',
            }}>
              Oh no!
            </Typography>

            <Typography variant="h3" style={{
              marginBottom: 16,
              whiteSpace: 'pre-line',
              lineHeight: 1.4,
              color: '#621B16',
              textAlign: 'center',
            }}>
              Page Not Found.
            </Typography>

            <Typography variant="subtitle1" style={{
              marginBottom: 64,
              whiteSpace: 'pre-line',
              lineHeight: 1.4,
              color: '#621B16',
              textAlign: 'center',
            }}>
              {message}
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ mb: 4, color: '#FFF', backgroundColor: '#C3362B' }}
              onClick={ reload }
            >
              Try again
            </Button>

            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ mb: 4, color: '#FFF', backgroundColor: '#C3362B' }}
              onClick={ () => history.push('/') }
            >
              Return home
            </Button>

            <ErrorIceCream sx={{ width: '208px', height: '187px', bottom: '64px', position: 'fixed' }} />
          </Container>

        );
      }}
    />
  );
}
