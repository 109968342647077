import { CSSProperties, FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useContact } from '../hooks/use-contact';


export type ProfileLinkProps = {
  contactId: string,
  children?: ReactNode,
  style?: CSSProperties,
}


export const ProfileLink: FC<ProfileLinkProps> = function ProfileLink({ contactId, children=undefined, ...rest }) {

  const { data, error } = useContact(contactId);

  if(error) {
    console.error("Couldn't resolve contact for ProfileLink", { contactId, error });
    return <></>;
  }

  if (!children) {
    children = data?.contact?.username;
  }

  return (
    <Link to={`/@${data?.contact?.username}`} {...rest}>
      {children}
    </Link>
  );
}
