import PropTypes from 'prop-types';
import { Box } from '@mui/material';


export function Mark({ children, ...props }) {

  return (
    <Box component='mark' {...props}>{children}</Box>
  );
}

Mark.propTypes = {
  children: PropTypes.node,
};
