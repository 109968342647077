import { FC, useEffect, useState, SyntheticEvent, useCallback, useRef, Fragment } from 'react';
import { Box, Divider, NoSsr, PaletteColor, Tooltip, Typography } from '@mui/material';

import { Comment as CommentType, Contact, Share } from '../../generated/graphql';
import { formatAge } from '../../utils/time';
import { useAnalyticsQueued } from '../../hooks/delicious-analytics';
import { useSession } from '../../hooks/auth';
import { useNavigationActions } from '../../hooks/history';
import { Avatar } from '../Avatar';
import { ChatOverlay, ChatOverlayProps } from '../ChatOverlay';
import { Comment, CommentProps } from '../Comment';
import { ChatOverlayMessageCard, ChatOverlayMessageCardProps } from './ChatOverlayMessageCard';


export type CommentsProps = {
  share: Pick<Share, '_id' | 'createdAt'> & ChatOverlayProps['share'] & CommentProps['share'] & ChatOverlayMessageCardProps['share'],
  comments: Array<
    Pick<CommentType, '_id' | 'createdAt'> & {
      author?: Pick<Contact, '_id'> | null,
    } & CommentProps['comment'] & ChatOverlayProps['comments'][number]
  >
  numberOfExpandedComments?: number,
  contextColor: PaletteColor,
  showCommentView?: string,
  setShowCommentView: (shareId: string) => void,
  hideAddComment?: boolean
}


export const Comments: FC<CommentsProps> = function Comments({ share, comments, numberOfExpandedComments=2, contextColor, showCommentView, setShowCommentView, hideAddComment=false }) {

  const { track } = useAnalyticsQueued();
  const { user } = useSession();
  const { closeSheet } = useNavigationActions();
  const blockCloseRef = useRef(false);

  const [focusInput, setFocusInput] = useState(false);

  useEffect(() => {
    if (showCommentView === share._id) {
      track('comment_sheet', { category: 'comments', origin: 'post' });
    }
  }, [ showCommentView, track, share._id ]);

  const handleClose = useCallback((ev: SyntheticEvent) => {
    ev?.stopPropagation();
    ev?.preventDefault();

    // Throttle because click on backdrop sometimes fires twice
    if(blockCloseRef.current) {
      return;
    }
    blockCloseRef.current = true;
    const timeoutHandle = setTimeout(() => {
      blockCloseRef.current = false;
    }, 100);

    setFocusInput(false);
    closeSheet('showCommentView');

    return () => clearTimeout(timeoutHandle);
  }, [ closeSheet ]);

  const handleClick = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    setFocusInput(true);
    setShowCommentView(share._id);
  }, [ setShowCommentView, share._id ]);

  // initialize prevAge to post createdAt so that the first age will be skipped if it is the same as the post
  let prevAge = formatAge(new Date(share.createdAt), 'long');

  return (
    <>
      {comments.length > numberOfExpandedComments &&
        <Typography
          variant='body1'
          onClick={handleClick}
          sx={{ px: 3, pt: 1 }}
          color='text.secondary'
        >
          Show all {comments.length} comments
        </Typography>
      }

      {comments.length > 0 &&
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {comments.slice(-1 * numberOfExpandedComments).map((comment, idx, all) => {
            const age = formatAge(new Date(comment.createdAt), 'long');
            let ageComponent = null;
            if(age !== prevAge) {
              // eslint-disable-next-line react-compiler/react-compiler
              prevAge = age;
              ageComponent = (
                <Box sx={{ display: 'flex', pt: 1, pb: 0, alignSelf: 'center', gap: 2, width: '100%', px: 4 }}>
                  <Divider sx={{ alignSelf: 'center', flex: '1 1 auto', color: 'grey.50' }} />
                  <Tooltip title={<NoSsr>{new Date(comment.createdAt).toLocaleString()}</NoSsr>}>
                    <Typography variant='body2' color='text.secondary'>
                      {age}
                    </Typography>
                  </Tooltip>
                  <Divider sx={{ alignSelf: 'center', flex: '1 1 auto', color: 'grey.50' }} />
                </Box>
              );
            }
            return (
              <Fragment key={comment._id}>
                {ageComponent}
                <Comment share={share} comment={comment} key={comment._id} contextColor={contextColor.background} showAuthor={(all[idx-1] && all[idx-1].author?._id === comment.author?._id) ? false : true} />
              </Fragment>
            );
          })}
        </Box>
      }

      {!hideAddComment &&
        <Box
          sx={{ mt: 2, px: 2, display: 'flex', alignItems: 'center' }}
          onClick={handleClick}
          data-cy='add-comment'
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{ mr: '6px' }}
              size='small'
              contact={user}
            />
            <Typography variant='body1' color='text.secondary'>
              {comments.length > 0 ? 'Add comment...' : 'Be the first to comment...'}
            </Typography>
          </Box>
        </Box>
      }

      <ChatOverlay
        open={showCommentView === share._id}
        share={share}
        comments={comments}
        focusInput={focusInput}
        onClose={handleClose}
        contextColor={contextColor}
      >
        <ChatOverlayMessageCard share={share} />
      </ChatOverlay>
    </>
  );
}
