import { PropTypes } from "prop-types";
import { Box, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { FallbackImage } from "./FallbackImage";
import { Image } from "./Image";
import { TypeYear } from "./canonical/TypeYear";


export function CanonicalSuggestion({ suggestion, onClick, ...rest }) {

  const theme = useTheme();

  const image = suggestion.canonicalContent.movie?.poster || suggestion.canonicalContent.tvshow?.poster;
  const title = suggestion.canonicalContent.movie?.title || suggestion.canonicalContent.tvshow?.title;

  return (
    <>
      <Box onClick={onClick} sx={{ my: 1, display: 'flex', height: 64, borderRadius: 1, backgroundColor: theme.palette.grey[100], maxWidth: '100%', overflow: 'hidden' }} {...rest}>
        <Box sx={{ flex: '0 0 auto', width: 64, height: 64 }}>
          {image &&
            <Image {...image} constraints="128x128_c" width={128} height={128} />
          }
          {!image &&
            <Box style={{ width: '100%', height:'100%' }}>
              <FallbackImage
                style={{
                  width: '100%',
                  height:'100%',
                }}
                color={theme.palette.primary.light}
                scale={2}
              />
            </Box>
          }
        </Box>
        <Box sx={{ flex: 1, px: 1, alignSelf: 'center', overflow: 'hidden' }}>
          {suggestion.highlight.some(h => h.key === 'title') &&
            <Typography variant="body1" noWrap>
              <span dangerouslySetInnerHTML={ { __html: suggestion.highlight.find(h => h.key === 'title').html}}></span>
            </Typography>
          }
          {!suggestion.highlight.some(h => h.key === 'title') &&
            <Typography variant="body1" noWrap>{title}</Typography>
          }
          <Typography variant="body2" noWrap sx={{ color: theme.palette.text.secondary }}>
            <TypeYear canonical={suggestion.canonicalContent} />
          </Typography>
          {suggestion.highlight.some(h => h.key === 'aka') && !suggestion.highlight.some(h => h.key === 'title') &&
            <Typography variant="body2" noWrap sx={{ color: theme.palette.text.secondary }}>
              aka {'"'}<span dangerouslySetInnerHTML={ { __html: suggestion.highlight.find(h => h.key === 'aka').html}}></span>{'"'}
            </Typography>
          }
        </Box>

      </Box>

    </>
  );
}
CanonicalSuggestion.propTypes = {
  suggestion: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};
