import { FC, ReactNode, SyntheticEvent } from 'react';
import { Typography, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { Contact, Group, useGetGroupsQuery } from '../generated/graphql';
import { useSession } from '../hooks/auth';
import { ContactName } from './ContactName';


export type ShareContextProps = {
  context?: {
    group?: {
      _id: string,
      name: string,
      color: string,
    } | null,
    type?: string | null,
  } | null,
  link?: boolean,
  sx?: SxProps,
};


export const ShareContext: FC<ShareContextProps> = function ShareContext({ context, link=true, sx = {} }) {

  const theme = useTheme();
  const { user, auth } = useSession();

  const { data, error, refetch } = useGetGroupsQuery({
    skip: !context?.group || !auth,
  });

  if(error) {
    console.log("Couldn't fetch groups in Context, ignoring error", { errorStr: error.toString(), error, context });
  }

  let group: Pick<Group, '_id' | 'name' | 'color'> & { members: { person: Pick<Contact, '_id'> }[] } | undefined;
  if(context?.group?._id) {
    if(data?.currentUser?.groups) {
      const groupId = context.group._id.split('@')[0];
      group = data.currentUser.groups.find(g => g._id === groupId);
    }
    if(!group) {
      group = { ...context.group, members: [] };
      refetch();
    }
  }

  let color = theme.palette.grey[100];
  let textColor = 'text.secondary';

  if(context?.type === 'public-list') {
    color = theme.palette.groups.public.light;
  } else if(context?.type === 'group' && group?.name && group?.color) {

    if(group.color in theme.palette.groups) {
      const c = group.color as keyof typeof theme.palette.groups;
      color = theme.palette.groups[c].light;
      textColor = theme.palette.groups[c].contrastText;
      if(!color) {
        color = group.color;
      }
    } else {
      color = group.color;
    }
  }

  const onClick = (e: SyntheticEvent) => e.stopPropagation();

  let contextComponent;
  if (context?.type === 'group' && group?.name === '' && group.members.filter(member => member.person && member.person._id !== user?._id).length === 1) {

    const member = group.members.filter(member => member.person && member.person._id !== user?._id)[0];
    const memberName = <ContactName key={member.person._id} contact={member.person} type='username' prefix='' />

    contextComponent = (<>{memberName}</>);


  } else if (context?.type === 'group' && group?.name === '') {

    const members = group.members.filter(member => member.person && member.person._id !== user?._id);
    const memberNames = members.map(member => <ContactName key={member.person._id} contact={member.person} type='username' prefix='' />);
    const memberNamesJoined = memberNames.reduce((prev: (string | ReactNode)[], curr) => prev.length === 0 ? [curr] : [...prev, ', ', curr], []);

    contextComponent = memberNamesJoined;

  } else if (context?.type === 'group' && group?.name) {

    contextComponent = group.name;

  } else {

    return null;
  }

  contextComponent = (
    <Typography variant='h4' noWrap sx={{ display: 'inline', backgroundColor: color, color: textColor, borderRadius: 0.5, px: 0.5, flex: '0 1 auto', ...sx }}>
      {contextComponent}
    </Typography>
  );

  if(link) {
    return (
      <Link to={`/groups/${group._id}`} onClick={onClick} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: '0 1 auto' }}>
        {contextComponent}
      </Link>
    );
  }

  return contextComponent;

}
