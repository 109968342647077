import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Chip as MuiChip, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Edit3 from 'react-feather/dist/icons/edit-3';


export const EditableChip = function ({ pre, text, onBlur, borderColor, color, fontSize, ...rest }) {

  const theme = useTheme();
  const ref = useRef(null);
  const [ edit, setEdit ] = useState(false);
  const append = (val) => {
    if (pre) {
      return val?.replace(new RegExp(`^${pre}?`), pre);
    }
    return val;
  };
  const [ value, setValue ] = useState(append(text));

  const editIcon = (<Edit3 style={{ fill: 'none', stroke: theme.palette.text.primary, strokeWidth: 1.5 }} />);

  const blur = () => {
    setEdit(false);
    onBlur(value.replace(new RegExp(`^${pre}?`), ''));
  };

  return (
    <Box {...rest}>
      {edit ? (
        <TextField
          autoFocus={true}
          variant="outlined"
          size="small"
          defaultValue={value}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: borderColor || theme.palette.grey['300'],
              borderRadius: '16px',
              whiteSpace: 'normal',
              lineHeight: '1.5em',
              height: '30px',
              fontSize: fontSize || '20px',
              fontWeight: 500,
              backgroundColor: color || theme.palette.grey['50'],
            },
            '& .MuiOutlinedInput-input': {
              fontSize: '20px',
              fontWeight: 500,
              color: theme.palette.text.primary
            },
            '& .Mui-focused': {
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: borderColor || theme.palette.grey['300'],
            },
            '& .MuiOutlinedInput-notchedOutline': {
              display: 'none'
            }
          }}
          onBlur={blur}
          onChange={ (event) => { setValue(append(event.target.value)) } }
          InputProps={{
            // eslint-disable-next-line react-compiler/react-compiler
            style: { width: `${ref.current?.offsetWidth}px` }
          }}
        />
      ) : (
        <MuiChip
          ref={ref}
          deleteIcon={editIcon}
          label={value}
          onDelete={ () => setEdit(true) }
          onClick={ () => setEdit(true) }
          sx={{
            maxWidth: '98%',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: borderColor || theme.palette.grey['300'],
            height: '30px',
            fontSize: fontSize || '20px',
            lineHeight: '1.5em',
            fontWeight: '500',
            color: theme.palette.text.primary,
            backgroundColor: color || theme.palette.grey['50'],
            '&& .MuiChip-deleteIcon': {
              height: '16px',
              fill: theme.palette.text.primary
            },
          }}
        />
      )}
    </Box>
  );
}

EditableChip.propTypes = {
  pre: PropTypes.string,
  text: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
};
