import { FC, SyntheticEvent, memo, useState } from "react";
import { Box, Card, NoSsr, Tooltip, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { CanonicalContent, Contact, Share } from "../../generated/graphql";
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from "../../hooks/watch-state";
import { formatAge } from "../../utils/time";
import { CompactShareUnfurl, CompactShareUnfurlProps } from '../unfurl/CompactShareUnfurl';
import { SwipeableUnfurl } from '../unfurl/SwipeableUnfurl';
import { Dialog, DialogProps } from '../unfurl/Dialog';
import { User } from '../User';
import { ShareText, ShareTextProps } from '../ShareText';


export type ChatOverlayMessageCardProps = {
  share: Pick<Share, '_id' | 'createdAt'> & {
    sender?: Pick<Contact, '_id'> | null,
    unfurl: CompactShareUnfurlProps['share']['unfurl'],
    canonicalContent?: Pick<CanonicalContent, '_id'> | null,
  } & ShareTextProps['share'] & CompactShareUnfurlProps['share'] & DialogProps['share'],
};


export const ChatOverlayMessageCard: FC<ChatOverlayMessageCardProps> = memo(function ChatOverlayMessageCard({ share }: ChatOverlayMessageCardProps) {

  const theme = useTheme();

  const isWatched = useIsWatched(share?._id, share?.canonicalContent?._id);
  const { toggleWatched } = useToggleWatched();
  const setIsWatched = (v: boolean) => toggleWatched(share?.canonicalContent?._id, share?._id, v);
  const isInWatchlist = useIsInWatchlist(share?._id, share?.canonicalContent?._id);
  const toggleInWatchlist = useToggleInWatchlist();
  const setIsInWatchlist = (v: boolean) => toggleInWatchlist(share?.canonicalContent?._id, share?._id, v);

  const [ dialogOpen, setDialogOpen ] = useState<boolean>(false);

  return (
    <Card
      sx={{
        position: 'relative',
        mx: 2,
        p: 1,
        mb: 1,
        mt: 5,
        '&:last-of-type': { mb: 0 },
        borderRadius: '8px',
        backgroundColor: 'background.paper',
        border: `1px solid ${theme.palette.grey['100']}`,
        overflow: 'unset',
        boxShadow: 1,
      }}
      onClick={(ev: SyntheticEvent) => ev.stopPropagation() }
    >

      {share && share.sender &&
        <User
          userId={share.sender._id}
          addLink={false}
          size={'small'}
          postfix=' shared'
          sx={{ typography: { fontWeight: 500 } }}
        />
      }
      <Tooltip title={<NoSsr>{new Date(share.createdAt).toLocaleString()}</NoSsr>}>
        <Typography variant='body2' sx={{ position: 'absolute', top: '8px', right: '8px', color: 'text.secondary', zIndex: 1 }}>
          {formatAge(new Date(share.createdAt), 'short')}
        </Typography>
      </Tooltip>
      <Box sx={{
        my: '6px',
      }}>
        <ShareText share={share} link={false} collapse={false} />
      </Box>

      {share.unfurl &&
        <Box sx={{ width: '95%' }}>
          <SwipeableUnfurl
            isWatched={isWatched}
            setIsWatched={setIsWatched}
            isInWatchlist={isInWatchlist}
            setIsInWatchlist={setIsInWatchlist}
          >
            <CompactShareUnfurl
              share={{ ...share, unfurl: share.unfurl }}
              sx={{ border: 'none', backgroundColor: 'grey.100' }}
              setDialogOpen={setDialogOpen}
            />
          </SwipeableUnfurl>
        </Box>
      }

      {dialogOpen &&
        <Dialog share={share} canonicalContent={undefined} open={dialogOpen} setOpen={setDialogOpen} />
      }

    </Card>
  );
});
