import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

import { Contact, Group, useGetGroupQuery } from "../generated/graphql";
import { useSession } from "../hooks/auth";
import { ContactName } from "./ContactName";


type SimpleGroup = Pick<Group, '_id' | 'name' | 'color'> & {
  members: {
    person: Pick<Contact, '_id' | 'username'>,
  }[]
};


export const GroupName: FC<{ groupId: string }> = function GroupName({ groupId }) {

  const { user } = useSession();

  const { data, loading, error } = useGetGroupQuery({
    skip: !groupId,
    variables: { _id: groupId },
  });

  if(error) {
    console.error(error);
  }

  if(loading) {
    return null;
  }

  const group = data?.currentUser?.group || (user.groups as SimpleGroup[]).find(g => g._id === groupId) || null;

  if(!group) {
    return null;
  }

  const members = group.members.map(m => m.person)?.filter(m => m && m._id !== user._id) || [] as Contact[];

  if(group.name) {
    return <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{group.name}</Box>;
  } else {
    if(members.length > 0) {
      const memberNames = members.map(member => <ContactName key={member._id} contact={member} type='username' prefix='' />);
      const memberNamesJoined = memberNames.reduce((prev, curr) => prev.length === 0 ? [curr] : [...prev, ', ', curr], [] as ReactNode[]);
      return <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{memberNamesJoined}</Box>;
    } else if(group.members.length > 0) {
      // Should only happen if user is the only member of the group
      return <ContactName key={group.members[0].person._id} contact={group.members[0].person} type='username' prefix='' />;
    }
  }

  return null;
}
