import { Typography } from '@mui/material';
import { FC } from 'react';

import { injectIntoParagraphs, Injector, Segment } from '../utils/inject';
import { Mention } from './Mention';
import { TextLink } from './TextLink';


export type DecoratedTextProps = {
  text: string,
  links?: Injector[] | null,
  mentions?: Injector[] | null,
  link?: boolean,
  splitParagraphs?: boolean,
};

export const DecoratedText: FC<DecoratedTextProps> = function DecoratedText({ text='', links=[], mentions=[], link=true, splitParagraphs=true }) {

  let paragraphs: Segment[][] = splitParagraphs ? text.split(/\r?\n\r?\n/).map(p => [p]) : [[text]];

  if(links?.length) {
    paragraphs = injectIntoParagraphs(paragraphs, links, (textLink, key) => (
      <TextLink key={key} textLink={textLink} addLink={link} />
    ));
  }

  if(mentions?.length) {
    paragraphs = injectIntoParagraphs(paragraphs, mentions, (mention, key) => (
      <Mention key={key} mention={mention} addLink={link} />
    ));
  }

  return (
    <>
      {paragraphs.map((paragraphSegments, i) => (
        <Typography component='p' sx={{ mb: 1, '&:last-child': { mb: 0 } }} key={i}>
          {paragraphSegments}
        </Typography>
      ))}
    </>
  );
}
