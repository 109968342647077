import { FC, Suspense, useCallback, useEffect } from "react";
import { Container, NoSsr } from "@mui/material";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import loadable from '@loadable/component';

import { isEmpty } from "../../utils/object";
import { useLoginPush } from "../../hooks/login-push";
import { useSession } from "../../hooks/auth";
import { useAnalyticsQueued } from "../../hooks/delicious-analytics";
const WelcomeContent = loadable(() => import(/* webpackChunkName: "welcome" */'../../views/auth/WelcomeContent'));


export const Welcome: FC<{ fabRadius: string, fabCenterOffset: string }> = function Welcome({ fabRadius, fabCenterOffset }) {

  const history = useHistory();
  const { user } = useSession();
  const { track } = useAnalyticsQueued();

  const { setDisplayState } = useLoginPush();

  const router = useCallback((action: 'login' | 'register' | 'test' | 'magic' | 'forgot', params?: { next?: string, oobCode?: string }) => {
    setDisplayState('hidden');

    const paramsObj = { ...params };
    let search = '';
    if (!isEmpty(paramsObj)) {
      search = `?${(new URLSearchParams(paramsObj)).toString()}`;
    }
    history.push(`/auth/${action}${search}`);
  }, [history, setDisplayState]);

  useEffect(() => {
    if(!user) {
      track(`show_welcome_overlay`, { category: 'not-logged-in' });
    }
  }, [user, track]);

  if(user) {
    return null;
  }

  return (
    <NoSsr>
      <Container
        maxWidth='sm'
        sx={{
          position: 'fixed',
          bottom: 0,
          transform: 'translate(calc(50vw - 50%))',
          backgroundColor: 'primary.background',
          color: 'text.primary',
          zIndex: 1001,
          height: '100%',

          flex: '1 1 auto',
          p: 5,
          pt: 'calc(var(--sat) + 40px)',

          boxShadow: '0px 0px 10px 1px rgb(0 0 0 / 50%)',
          'body.auth-open &': { display: 'none' },

          '&.shake': {
            animation: 'shake 1s ease',
          },
        }}

        component={motion.div}
        key="not-logged-in"
        initial={{ clipPath: `circle(${fabRadius} at ${fabCenterOffset} ${fabCenterOffset})`, display: 'block' }}
        animate={{ clipPath: `circle(2000px at ${fabCenterOffset} ${fabCenterOffset})`, display: 'block' }}
        exit={{ clipPath: `circle(${fabRadius} at ${fabCenterOffset} ${fabCenterOffset})`, transitionEnd: { display: "none" }, }}
        transition={{ duration: 0.2 }}
      >
        <Suspense fallback={null}>
          <WelcomeContent router={router} />
        </Suspense>

      </Container>
    </NoSsr>
  );
}

export default Welcome;
