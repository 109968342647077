import { useState, useEffect, useCallback, FC, SyntheticEvent } from 'react';
import { Capacitor } from '@capacitor/core';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { useMutation } from '@apollo/client';
import { Email as EmailIcon } from '@mui/icons-material';
import * as EmailValidator from 'email-validator';

import { useTranslation } from '@/utils/i18n';
import { REQUEST_LOGIN_EMAIL } from '@/queries/auth';
import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { useSnackbar } from '@/hooks/snackbar';
import { EmailInput, EmailInputProps } from './EmailInput';


export type MagicFormProps = {
  email: string,
  EmailInputProps: Omit<EmailInputProps, 'email'>,
} & LoadingButtonProps;


export const MagicForm: FC<MagicFormProps> = function MagicForm({ email, EmailInputProps, ...props }) {

  const { t } = useTranslation('auth');
  const { track } = useAnalyticsQueued();
  const [ showLoading, setShowLoading ] = useState(false);
  const { show } = useSnackbar();

  const [ requestLoginEmail, { error } ] = useMutation(REQUEST_LOGIN_EMAIL, {
    onCompleted({ requestLoginEmail }) {
      if(requestLoginEmail.status === 'email-not-found') {
        track('magic_link_email_not_found', { category: 'auth' });
        console.error('Email not found for magic link');
        show({ text: 'Email not found', severity: 'error' });
        setShowLoading(false);
      } else {
        track('magic_link_sent', { category: 'auth' });
        show({ text: 'Open your email and click the magic link', severity: 'info' });
        setShowLoading(false);
      }
    },
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      setShowLoading(false);
      show({ text: error.message, severity: 'error' });
    }
  }, [ error, show ]);

  const submit = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track('magic_link_request', { category: 'auth' });
    setShowLoading(true);
    requestLoginEmail({ variables: { email, platform: Capacitor.getPlatform() } });
  }, [ email, requestLoginEmail, track ]);

  return (
    <form onSubmit={submit}>

      <EmailInput email={email} {...EmailInputProps} />

      <LoadingButton
        variant="contained"
        fullWidth
        loading={showLoading}
        loadingPosition="start"
        disabled={!EmailValidator.validate(email)}
        onClick={submit}
        startIcon={ <EmailIcon style={{ fontSize: '1.0rem'}} /> }
        {...props}
      >
        {t('send_magic_link')}
      </LoadingButton>

    </form>
  );
}
