import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';


type TransitionState = 'visible' | 'hidden' | 'willAppear' | 'willDisappear';

const duration = 0.25;

const transitionClasses: Record<TransitionState, object> = {
  visible: {
    opacity: 1,
    pointerEvents: 'all',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'hidden',
    contentVisibility: 'hidden',
    transform: 'scaleX(0)',
  },
  willAppear: {
    opacity: 1,
    pointerEvents: 'none',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: `opacity ${duration}s ease-in-out`,
  },
  willDisappear: {
    opacity: 0,
    pointerEvents: 'none',
    visibility: 'visible',
    contentVisibility: 'visible',
    transform: 'scaleX(1)',
    transition: `opacity ${duration}s ease-in-out`,
  },
};

const css = Object.fromEntries(Object.entries(transitionClasses).map(([className, styles]) => [`&.${className}`, styles]));


export const FadeTransition: FC<{ match: boolean, children: ReactNode }> = function FadeTransition({ match, children }) {
  const nodeRef = useRef(null);
  const history = useHistory();

  const animationTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [animationState, setAnimationState] = useState<TransitionState>(match ? 'visible' : 'hidden');

  useEffect(() => {
    if(match) {
      if(animationState === 'hidden') {
        if(history.action === 'POP') {
          setAnimationState('visible');
        } else {
          setAnimationState('willAppear');
          setTimeout(() => {
            setAnimationState('visible');
          }, duration*1000);
        }
      } else if(animationState === 'willDisappear') {
        if(animationTimeoutRef.current) {
          clearTimeout(animationTimeoutRef.current);
        }
        setAnimationState('visible');
      } else if(animationState === 'willAppear') {
        // do nothing
      } else if(animationState === 'visible') {
        // do nothing
      } else {
        throw new Error(`Unexpected animation state: ${animationState}, match: ${match}`);
      }
    } else {
      if(animationState === 'visible') {
        if(history.action === 'POP') {
          setAnimationState('hidden');
        } else {
          setAnimationState('willDisappear');
          animationTimeoutRef.current = setTimeout(() => {
            setAnimationState('hidden');
          }, duration*1000);
        }
      } else if(animationState === 'willAppear') {
        if(animationTimeoutRef.current) {
          clearTimeout(animationTimeoutRef.current);
        }
        setAnimationState('hidden');
      } else if(animationState === 'willDisappear') {
        // do nothing
      } else if(animationState === 'hidden') {
        // do nothing
      } else {
        throw new Error(`Unexpected animation state: ${animationState}, match: ${match}`);
      }
    }
  }, [match, history.action, animationState]);

  const isVisible = animationState === 'visible';

  return (
    <Box
      ref={nodeRef}
      className={clsx({ [animationState]: true })}
      sx={css}
    >
      <Box sx={{
        height: '100%',
        '& textarea, & input': {
          visibility: isVisible ? 'inherit' : 'hidden',
          contentVisibility: isVisible ? 'inherit' : 'hidden',
        }
      }}>
        <div>
          {children}
        </div>
      </Box>
    </Box>
  );
}
