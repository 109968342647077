import { FC } from "react";
import { Box, SxProps } from "@mui/material";
import { Image } from "./Image";


export const ProviderLogo: FC<{ name: string, logo: { hash: string }, size?: 'small' | 'tiny', sx?: SxProps }> = ({ name, logo, size='normal', sx={} }) => {

  let side = 48;
  let borderRadius = '8px';
  let fontSize = '30px';

  if(size === 'small') {
    side = 32;
    borderRadius = '4px';
    fontSize = '20px';
  } else if(size === 'tiny') {
    side = 20;
    borderRadius = '2px';
    fontSize = '14px';
  }

  return (
    <>
      {logo &&
        <Box sx={{ width: side, height: side, borderRadius, overflow: 'hidden', backgroundColor: 'background.paper', ...sx }}>
          <Image {...logo} width={side} height={side} alt='' constraints='96x96_c' loading="lazy" />
        </Box>
      }
      {!logo &&
        <Box sx={{ width: side, height: side, borderRadius, backgroundColor: 'grey.500', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: fontSize, ...sx }}>
          {name[0]}
        </Box>
      }
    </>
  );
};
