import PropTypes from 'prop-types';
import { Button } from "@mui/material";


export function DepressButton({ isDepressed, children, sx, ...rest }) {
  return (
    <Button
      {...rest}
      variant='contained'
      className={isDepressed ? 'isDepressed' : ''}
      sx={{
        '&:before': {
          boxShadow: 8,
          content: '""',
          position: 'absolute',
          inset: 0,
          borderRadius: 'inherit',
          transition: 'opacity 0.15s ease-out',
          opacity: 1,
        },
        '&:after': {
          boxShadow: 9,
          content: '""',
          position: 'absolute',
          inset: 0,
          borderRadius: 'inherit',
          transition: 'opacity 0.15s ease-out ',
          opacity: 0,
        },
        '&.isDepressed:after': {
          opacity: 1,
        },
        '&.isDepressed:before': {
          opacity: 0,
        },
        '&.isDepressed > *': {
          transform: 'translateY(1px)'
        },
        '& > *': {
          transition: 'transform 0.15s ease-in-out',
        },

        boxShadow: 'none',
        ...sx,
      }}
    >
      <span>{children}</span>
    </Button>
  );
}
DepressButton.propTypes = {
  isDepressed: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
