import { FC } from "react";
import { Box, SxProps } from "@mui/material";


const genreIcons = {
  'Action': '💥',
  'Adventure': '🗺',
  'Animation': '🐰',
  'Biography': '📖',
  'Comedy': '😂',
  'Crime': '🔫',
  'Documentary': '📽️',
  'Drama': '🎭',
  'Family': '👨‍👩‍👧‍👦',
  'Fantasy': '🧙',
  'Film-Noir': '🕶️',
  'History': '📜',
  'Horror': '👻',
  'Music': '🎵',
  'Musical': '🎤',
  'Mystery': '🕵️',
  'Romance': '💑',
  'Sci-Fi': '🚀',
  'Short': '📹',
  'Sport': '🏀',
  'Thriller': '🔪',
  'War': '🔫',
  'Western': '🤠'
} as Record<string, string>;


export const GenreChip: FC<{ genre: string, sx?: SxProps }> = function GenreChip({ genre, sx={} }) {
  return (
    <Box
      sx={{
        color: 'text.primary',
        fontSize: '14px',
        fontWeight: 400,
        borderRadius: 0.5,
        backgroundColor: 'grey.300',
        px: '8px',
        py: '1.5px',
        display: 'flex',
        alignItems: 'center',
        gap: '2px',
        ...sx
      }}
    >
      <div style={{ fontSize: '0.8em' }}>{genreIcons[genre]}</div>{genre}
    </Box>
  );
}

