import PropTypes from 'prop-types';
import SimplePullToRefresh from 'react-simple-pull-to-refresh';
import { Box } from '@mui/material';


export function PullToRefresh({ children, onRefresh, isPullable, color }) {

  if(typeof isPullable !== 'boolean') {
    isPullable = !!onRefresh;
  }

  if(!onRefresh) {
    onRefresh = () => {};
  }

  return (
    <Box sx={{
      display: 'flex',
      backgroundSize: 'contain',
      backgroundPosition: 'center top',
      backgroundColor: 'background.paper',
      height: '100%',
      position: 'relative',
      flexDirection: 'column',

      '&& .ptr__pull-down--pull-more': {
        display: 'none'
      },
      '&& .ptr__loader .lds-ellipsis div': {
        backgroundColor: color || 'secondary.light'
      }
    }}>
      <SimplePullToRefresh
        isPullable={isPullable}
        onRefresh={onRefresh}
        maxPullDownDistance={200}
        // refreshingContent=''
        pullingContent=''
      >
        {children}
      </SimplePullToRefresh>
    </Box>
  );
}

PullToRefresh.propTypes = {
  onRefresh: PropTypes.func,
  children: PropTypes.any,
  isPullable: PropTypes.bool,
  color: PropTypes.string,
};
