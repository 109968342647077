import { FC } from 'react';
import { useLocation } from 'react-router';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Flag } from 'react-feather';

import { Contact, List, Share, useReportMutation } from '../generated/graphql';


export const FlagButton: FC<{ share?: Pick<Share, '_id'>, list?: Pick<List, '_id'>, contact?: Pick<Contact, '_id'>, text?: string }> = function ({ share, list, contact, text=undefined }) {
  const location = useLocation();
  const [report, { data, loading, error }] = useReportMutation({ variables: { input: {
    listId: list?._id,
    shareId: share?._id,
    contactId: contact?._id,
    url: location.pathname+location.search,
    type: 'flag'
  } } });

  let buttonText = text || 'Report inappropriate content';
  if(loading) {
    buttonText = 'Reporting...';
  } else if(error) {
    buttonText = 'Something went wrong';
  } else if(data) {
    buttonText = 'Thank you for reporting';
  }

  return (
    <ListItem button sx={{ p: 0, py: '6px' }} onClick={() => report()}>
      <ListItemIcon sx={{ minWidth: 32 }}>
        <Flag />
      </ListItemIcon>
      <ListItemText primary={buttonText} />
    </ListItem>
  )
};
