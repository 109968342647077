import { FC } from 'react';
import { useLocation } from 'react-router';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { XOctagon } from 'react-feather';

import { Blocked, Contact, useReportMutation, useUnblockMutation } from '../generated/graphql';
import { useSession } from '../hooks/auth';
import { GET_USER_DATA } from '../queries/user';


export const BlockContactButton: FC<{ contact: Pick<Contact, '_id' | 'username'> }> = function ({ contact }) {

  const location = useLocation();
  const { user } = useSession();

  const [report, { data, loading, error }] = useReportMutation({
    variables: {
      input: { contactId: contact._id, url: location.pathname+location.search, type: 'block' }
    },
    refetchQueries: [GET_USER_DATA]
  });
  const [unblock, { data: unblockData, loading: unblockLoading, error: unblockError }] = useUnblockMutation({
    variables: {
      contactId: contact._id
    },
    refetchQueries: [GET_USER_DATA]
  });

  let blocked = false;
  if(user?.blocked?.some((b: Blocked) => b.contact === contact._id)) {
    blocked = true;
  }

  const handleClick = () => {
    if(blocked) {
      unblock();
    } else {
      report();
    }
  };

  let text;
  if(blocked) {
    text = `Unblock @${contact.username}`;
    if(unblockLoading) {
      text = 'Unblocking...';
    } else if(unblockError) {
      text = 'Something went wrong';
    } else if(unblockData?.unblock?.status === 'ok') {
      text = 'The user has been unblocked';
    }
  } else {
    text = `Block @${contact.username}`;
    if(loading) {
      text = 'Blocking...';
    } else if(error) {
      text = 'Something went wrong';
    } else if(data) {
      text = 'The user has been blocked';
    }
  }

  return (
    <ListItem button sx={{ p: 0, py: '6px' }} onClick={handleClick}>
      <ListItemIcon sx={{ minWidth: 32 }}>
        <XOctagon />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
};
