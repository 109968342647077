import { useState, useCallback, useMemo, FC, SyntheticEvent } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Apple } from '@mui/icons-material';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { OAuthProvider } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';
import { ButtonProps } from '@mui/material';

import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { useOauth } from '../../hooks/oauth';


type AppleButtonProps = {
  forceRedirect?: boolean;
} & ButtonProps;


export const AppleButton: FC<AppleButtonProps> = function AppleButton({ children, forceRedirect, ...props }) {

  const { track } = useAnalyticsQueued();
  const [ loading, setLoading ] = useState(false);
  const provider = useMemo(() => new OAuthProvider('apple.com'), []);
  const providerId = provider?.providerId?.replace('.', '_');

  const {
    signInWithCredential,
    signInWithRedirect,
    signInWithPopup,
    handlePopupError,
    preferRedirect
  } = useOauth({ provider, providerId });

  const redirect = useCallback(async () => {
    track(`${providerId}_oauth_start`, { category: 'auth', providerId, method: 'redirect' });
    signInWithRedirect();
    setLoading(false);
  }, [ signInWithRedirect, track, providerId ]);

  const popup = useCallback(async () => {
    track(`${providerId}_oauth_start`, { category: 'auth', providerId, method: 'popup' });
    setLoading(true);

    if (Capacitor.isNativePlatform()) {
      FirebaseAuthentication.signInWithApple()
        .then(result => {
          // translate to something the web env can use
          if(!result.credential?.idToken || !result.credential?.nonce) {
            throw new Error('Apple sign in failed, missing credential');
          }
          track(`${providerId}_oauth_success`, { category: 'auth', providerId, method: 'popup' });
          const credential = provider.credential({ idToken: result.credential.idToken, rawNonce: result.credential.nonce });
          signInWithCredential(credential).then(() => {
            track(`${providerId}_credential_success`, { category: 'auth', providerId, method: 'popup' })
          }).catch(err => {
            track(`${providerId}_credential_error`, { category: 'auth', providerId, method: 'popup', error: err.message })
          }).finally(() => setLoading(false));
        })
        .catch(err => {
          track(`${providerId}_oauth_error`, { category: 'auth', providerId, method: 'popup', error: err.message });
          handlePopupError(err);
          setLoading(false);
        });
    } else {
      signInWithPopup().finally(() => setLoading(false));
    }

  }, [ track, handlePopupError, signInWithPopup, signInWithCredential, provider, providerId ]);

  const handleClick = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track(`${providerId}_oauth_click`, { category: 'auth', providerId });
    if(forceRedirect || (preferRedirect && forceRedirect !== false)) {
      redirect();
    } else {
      popup();
    }
  }, [ track, redirect, popup, providerId, preferRedirect, forceRedirect ]);

  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      variant="contained"
      color="primary"
      fullWidth
      data-cy='apple-submit'
      onClick={handleClick}
      startIcon={<Apple style={{ fontSize: '1.0rem'}} />}
      sx={{ mb: 1 }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}
