import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Repeat } from 'react-feather';

import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { useSession } from '../hooks/auth';
import { useLoginPush } from '../hooks/login-push';
import { Button } from './Button';


export function InternalShareButton({ text, link, origin, hidden='', reshareOf, children, onClick=(()=>{}) }) {
  const history = useHistory();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();
  const { user } = useSession();

  const handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    onClick();

    if(!user) {
      track('click_repost_button', { category: 'not-logged-in' });
      setLoginHint('You need to be logged in use the share function.');
      return;
    }
    track('repost_button', { category: 'share', origin });
    const reshareParam = reshareOf ? `&reshare=${reshareOf}` : '';
    history.push(`/share?share-url=${encodeURIComponent(link)}&share-message=${encodeURIComponent(text)}&source=${origin}&hidden=${hidden}${reshareParam}`)
  };

  return (
    <Button variant='contained' size='small' color='primary' fullWidth onClick={handleClick}>
      {!children &&
        <>
          <Repeat size={16} style={{ marginRight: 4 }} />
          Share on Ice Cream Club
        </>
      }
      {children}
    </Button>
  );
}

InternalShareButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  origin: PropTypes.string,
  hidden: PropTypes.string,
  reshareOf: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};
