import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, SxProps, Typography } from '@mui/material';

import { useContact } from '../../hooks/use-contact';
import { Avatar } from '../Avatar';


export type PersonProps = {
  contactId: string,
  onClick?: () => void,
  size?: 'medium' | 'small',
  avatarStyle?: SxProps,
  sx?: SxProps & {
    typography?: SxProps,
  } | null,
}

export const Person: FC<PersonProps> = function Person({ contactId, onClick, size, sx={}, avatarStyle={}, ...rest }) {
  const history = useHistory();
  const { data, loading, error } = useContact(contactId);

  if(loading) {
    return "";
  }

  if(error) {
    console.error("Couldn't resolve contact for Person context", { contactId, error });
    return "";
  }

  if(!data?.contact) {
    console.error("Couldn't resolve contact for Person context", { contactId, data });
    return "";
  }

  const person = data.contact;

  if (!onClick) {
    onClick = () => history.push(`/@${person.username}`);
  }

  const { typography, ...restSx } = sx || {};

  return (
    <Box sx={{ lineHeight: '1.3em', display: 'flex', alignItems: 'center', ...restSx }} {...rest} onClick={onClick}>
      <Avatar key={person._id} contact={person} size={size} sx={{ mr: 1, ...avatarStyle }} />
      <Typography variant='h3' sx={typography}>
        {person.username}
      </Typography>
    </Box>
  );
}
