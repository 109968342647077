import { useState, useCallback, FC, SyntheticEvent } from 'react';
import { ButtonProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Twitter } from '@mui/icons-material';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { TwitterAuthProvider } from 'firebase/auth';
import { Capacitor } from '@capacitor/core';

import { useAnalyticsQueued } from '@/hooks/delicious-analytics';
import { useOauth } from '../../hooks/oauth';


type TwitterButtonProps = {
  forceRedirect?: boolean;
} & ButtonProps;


export const TwitterButton: FC<TwitterButtonProps> = function TwitterButton({ children, forceRedirect=false, ...props }) {

  const { track } = useAnalyticsQueued();
  const [ loading, setLoading ] = useState(false);
  const provider = new TwitterAuthProvider();
  provider.addScope('email');
  const providerId = provider.providerId.replace('.', '_');

  const {
    signInWithCredential,
    signInWithRedirect,
    signInWithPopup,
    handlePopupError,
    preferRedirect
  } = useOauth({ provider, providerId });

  const redirect = useCallback(async () => {
    track(`${providerId}_oauth_start`, { category: 'auth', providerId, method: 'redirect' });
    signInWithRedirect();
    setLoading(false);
  }, [ signInWithRedirect, track, providerId ]);

  const popup = useCallback(async () => {
    track(`${providerId}_oauth_start`, { category: 'auth', providerId, method: 'popup' });
    setLoading(true);

    if (Capacitor.isNativePlatform()) {
      FirebaseAuthentication.signInWithTwitter()
        .then(result => {
          // translate to something the web env can use
          if(!result.credential?.accessToken || !result.credential?.secret) {
            throw new Error('Twitter sign in failed, missing credential');
          }
          track(`${providerId}_oauth_success`, { category: 'auth', providerId, method: 'popup' });
          const credential = TwitterAuthProvider.credential(result.credential.accessToken, result.credential.secret);
          signInWithCredential(credential).then(() => {
            track(`${providerId}_credential_success`, { category: 'auth', providerId, method: 'popup' })
          }).catch(err => {
            track(`${providerId}_credential_error`, { category: 'auth', providerId, method: 'popup', error: err.message })
          }).finally(() => setLoading(false));
        })
        .catch(err => {
          track(`${providerId}_oauth_error`, { category: 'auth', providerId, method: 'popup', error: err.message });
          handlePopupError(err);
          setLoading(false);
        });
    } else {
      signInWithPopup().finally(() => setLoading(false));
    }

  }, [ track, handlePopupError, signInWithPopup, signInWithCredential, providerId ]);


  const handleClick = useCallback((ev: SyntheticEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    track(`${providerId}_oauth_click`, { category: 'auth', providerId });
    if(forceRedirect || (preferRedirect && forceRedirect !== false)) {
      redirect();
    } else {
      popup();
    }
  }, [ track, redirect, popup, providerId, preferRedirect, forceRedirect ]);


  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      variant="contained"
      color="primary"
      fullWidth
      data-cy='twitter-submit'
      onClick={handleClick}
      startIcon={<Twitter style={{ fontSize: '1.0rem'}} />}
      sx={{ mb: 1 }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
}
