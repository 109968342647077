import { FC, useCallback, useState } from 'react';
import { Box, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Trash } from 'react-feather';

import { List, useCreateListMutation, useDeleteListMutation, useUpdateListMutation } from '@/generated/graphql';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { GET_USERS_LISTS } from '../queries/list';
import { BottomSheet } from './BottomSheet';
import { Button } from './Button';


export type CreateCollectionProps = {
  onClose: () => void,
  open: boolean,
  list?: Pick<List, '_id' | 'name' | 'description' | 'isPublic'>,
}


export const CreateCollection: FC<CreateCollectionProps> = function CreateCollection({ onClose, open, list }) {

  const edit = list?._id !== undefined;

  const [name, setName] = useState(list?.name || '');
  const [description, setDescription] = useState(list?.description || '');
  const [isPublic, setIsPublic] = useState(edit ? list.isPublic : true);
  const validInput = !!name;

  const { track } = useAnalyticsQueued();
  const { enqueueSnackbar } = useSnackbar();

  const [createList, { loading: createLoading }] = useCreateListMutation({
    refetchQueries: [{ query: GET_USERS_LISTS }],
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  });

  const [updateList, { loading: updateLoading }] = useUpdateListMutation({
    refetchQueries: [{ query: GET_USERS_LISTS }],
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  });

  const [deleteList] = useDeleteListMutation({
    variables: { listId: list?._id || '' },
    refetchQueries: [{ query: GET_USERS_LISTS }],
    onError: (err) => {
      enqueueSnackbar(err.message, { variant: 'error' });
    },
  });

  const submit = useCallback(function() {
    if(edit) {
      updateList({ variables: { input: { name, isPublic, _id: list._id, description } } }).then(onClose);
      track('update_list', {category: 'bookmarks', is_public: `${isPublic}` });
    } else {
      createList({ variables: { input: { name, isPublic, description } } }).then(onClose);
      track('create_list', {category: 'bookmarks', is_public: `${isPublic}` });
    }
  }, [createList, description, edit, isPublic, list, name, onClose, track, updateList]);

  const restoreList = useCallback(() => {
    deleteList({ variables: { listId: list?._id || '', restore: true } }).then(() => {
      enqueueSnackbar(`Restored the collection "${list?.name}"`, {
        variant: 'warning',
        autoHideDuration: 8000,
      });
    })
  }, [deleteList, list?.name, enqueueSnackbar, list?._id]);

  const onDelete = useCallback(function() {
    deleteList().then(() => {
      const action = () => (
        <Button onClick={restoreList}>
          Undo
        </Button>
      );

      enqueueSnackbar(`Deleted the collection "${list?.name}"`, {
        variant: 'warning',
        autoHideDuration: 8000,
        action,
      });
      onClose();
    });
  }, [deleteList, list?.name, enqueueSnackbar, onClose, restoreList]);

  return (
    <BottomSheet
      onClose={onClose}
      onOpen={() => null}
      open={open}
      // close={<Button onClick={onClose} variant='text' color='primary' sx={{ ml: -1.5 }}>Cancel</Button>}
      disablePadding={false}
    >
      <Typography variant='h4' sx={{ mt: 2, mb: 1 }}>
        {edit ? 'Edit collection' : 'Create a new collection'}
      </Typography>
      {open &&
        <>
          <TextField
            color="primary"
            placeholder="Choose a name"
            fullWidth
            value={name}
            onChange={ev => setName(ev.target.value)}
            data-cy='createlist-text'
          />

          <TextField
            color="primary"
            placeholder="You can add a description to help your friends know what it's about"
            fullWidth
            multiline
            minRows={2}
            value={description}
            onChange={ev => setDescription(ev.target.value)}
            sx={{ mt: 2 }}
            data-cy='createlist-description'
          />

          <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>
            Let others share your taste?
          </Typography>
          <ToggleButtonGroup
            color="secondary"
            value={isPublic ? 'public' : 'private'}
            exclusive
            fullWidth
            onChange={(_, value) => value && setIsPublic(value === 'public')}
            size='medium'
          >
            <ToggleButton value="private" data-cy='createlist-private'>Private</ToggleButton>
            <ToggleButton value="public" data-cy='createlist-public'>Public</ToggleButton>
          </ToggleButtonGroup>

          {edit &&
            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant='h4'>
                Delete collection
              </Typography>
              <Button variant='outlined' size='small' color='warning' onClick={onDelete}>
                Delete
                <Trash style={{ width: 16, marginLeft: 8 }} />
              </Button>
            </Box>
          }

          <Button sx={{ mt: 3 }} variant='contained' size='large' fullWidth color='primary' disabled={!validInput || updateLoading || createLoading} shadowLevel={0} onClick={submit} data-cy='createlist-submit'>
            {edit ? 'Save' : 'Create'}
          </Button>
        </>
      }
    </BottomSheet>
  );
}
