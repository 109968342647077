import PropTypes from 'prop-types';

export const FileUploader = ({ onFileSelectError, onFileSelectSuccess, captureButton, uploadButton, ...rest }) => {

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    if(!file) {
      onFileSelectError({ error: 'No file selected' });
    } else if (file.size > 16*1024*1024) {
      onFileSelectError({ error: "File size cannot exceed more than 16MB" });
    } else {
      onFileSelectSuccess(file);
    }
  };

  return (
    <div {...rest}>
      <label>
        <input type="file" accept="image/*" capture="user" onChange={handleFileInput} style={{ display: 'none' }} />
        {captureButton}
      </label>
      <label>
        <input type="file" accept="image/*" onChange={handleFileInput} style={{ display: 'none' }} />
        {uploadButton}
      </label>
    </div>
  )
};

FileUploader.propTypes = {
  onFileSelectError: PropTypes.func,
  onFileSelectSuccess: PropTypes.func,
  captureButton: PropTypes.node.isRequired,
  uploadButton: PropTypes.node.isRequired,
};
