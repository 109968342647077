import { FC, MouseEvent } from "react";
import { Box, BoxProps } from "@mui/material";
import { useHistory } from "react-router";


type LinkBoxProps = BoxProps & {
  href: string;
  alt?: string;
};

export const LinkBox: FC<LinkBoxProps> = function LinkBox({ href, alt, children, ...rest }) {

  const history = useHistory();

  const handleClick = (ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    history.push(href);
  };

  return (
    <Box component='a' href={href} alt={alt} onClick={handleClick} {...rest}>
      {children}
    </Box>
  );
}
