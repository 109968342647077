import { FC, SyntheticEvent } from "react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";


type FilterButtonProps = {
  active: boolean,
  onClick: (ev: SyntheticEvent) => void,
} & ButtonBaseProps;


export const FilterButton: FC<FilterButtonProps> = function FilterButton({ active, onClick, children, sx={}, ...rest }) {
  return (
    <ButtonBase
      disableRipple={false}
      sx={{
        typography: 'body1',
        height: '56px',
        borderRadius: '4px',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: active ? 'text.primary' : 'grey.300',
        color: active ? 'text.primary' : 'grey.400',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 0.5,
        textAlign: 'center',
        flexDirection: 'column',
        ...sx,
      }}
      onClick={(ev) => setTimeout(() => onClick(ev), 100)}
      {...rest}
    >
      {children}
    </ButtonBase>
  );
}
