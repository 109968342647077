import { FC, ReactNode } from 'react';
import { SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { List } from '../generated/graphql';
import { Chip } from './Chip';
import { ListIcon } from './icons/ListIcon';


export type ListChipProps = {
  list: Pick<List, 'name'>,
  onClick: () => void,
  size?: 'small' | 'medium',
  variant?: 'normal' | 'selectable' | 'selected',
  sx: SxProps,
  icon: ReactNode,
}


export const ListChip: FC<ListChipProps> = function ListChip({ list, onClick, size='medium', variant='normal', sx, icon }) {
  const theme = useTheme();

  let chipColor, selectedGroupColor = '';
  if (variant === 'selectable') {
    chipColor = theme.palette.text.secondary;
    selectedGroupColor = theme.palette.text.primary;
  } else if (variant === 'normal') {
    chipColor = theme.palette.primary.main;
    icon = icon || <ListIcon />;
  } else {
    chipColor = theme.palette.text.primary;
  }

  return (

    <Chip
      variant={variant}
      label={list.name === 'Uncategorized' ? 'Inbox' : list.name}
      icon={icon}
      textColor={theme.palette.primary.contrastText}
      color={chipColor}
      selectedColor={selectedGroupColor}
      onClick={onClick}
      size={size}
      sx={sx}
    />
  );
}
