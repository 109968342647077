import { FC, SyntheticEvent } from "react";
import { Box, SxProps } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { MessageSquare } from "react-feather";
import { useHistory } from "react-router-dom";

import { CanonicalContent, Contact, Share } from "../generated/graphql";
import { canonicalLink } from "../utils/routes";
import { absolutize } from "../utils/url";


interface CommentButtonProps {
  author: Pick<Contact, "_id">,
  share?: Pick<Share, "_id"> | null,
  canonicalContent?: Pick<CanonicalContent, "_id"> | null,
  onClick?: (ev: SyntheticEvent) => void,
  sx?: SxProps,
}

export const CommentButton: FC<CommentButtonProps> = function CommentButton({ author, share, canonicalContent, onClick, sx={} }) {

  const theme = useTheme();
  const history = useHistory();

  let handleClickComment = onClick
  if(!handleClickComment) {
    handleClickComment = (ev: SyntheticEvent) => {
      ev.preventDefault();
      ev.stopPropagation();
      const link = absolutize(canonicalLink(canonicalContent?._id, share?._id));
      history.push(`/share?share-recipient=contact:${author._id}&share-url=${link}&hidden=recipients_lists_link`);
    };
  }

  return (
    <Box
      sx={{
        backgroundColor: 'grey.50',
        display: 'flex',
        width: '32px',
        height: '36px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 1,
        flex: '0 0 auto',
        ...sx,
      }}
      onClick={handleClickComment}
    >
      <MessageSquare
        style={{
          stroke: theme.palette.text.secondary,
        }}
        width={16}
        height={16}
      />
    </Box>
  );
}
