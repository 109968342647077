import { FC, KeyboardEvent, RefObject, SyntheticEvent } from 'react';
import { Box, CSSObject, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSession } from '../hooks/auth';
import { useIsVisible } from '../hooks/use-is-visible';
import { Avatar } from './Avatar';


export type ShareMessageProps = {
  message: string;
  setMessage: (message: string) => void;
  caretDummyRef?: RefObject<HTMLDivElement>;
  inputRef?: RefObject<HTMLInputElement>;
  onSubmit: (ev: SyntheticEvent) => void;
}


export const ShareMessage: FC<ShareMessageProps> = function ShareMessage({ message, setMessage, caretDummyRef, inputRef, onSubmit }) {

  const { user } = useSession();
  const theme = useTheme();
  const isVisible = useIsVisible();

  const caretDummyValue = inputRef?.current?.selectionStart ? message.substring(0, inputRef.current.selectionStart) : '';

  function handleKeyDown(ev: KeyboardEvent<HTMLInputElement>): void {
    if(ev.ctrlKey && ev.code === 'Enter') {
      onSubmit(ev);
    }
  }

  const themeRoot = (theme.components?.MuiOutlinedInput?.styleOverrides?.root || {}) as CSSObject;
  const inputColor = 'backgroundColor' in themeRoot ? themeRoot.backgroundColor : undefined;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
      }}
    >
      <Avatar
        sx={{ flex: '0 0 32px', mt: 0.5 }}
        size={'medium'}
        contact={user?._id}
      />

      <Box sx={{
        position: 'relative',
        width: '100%',
        backgroundColor: inputColor,
        p: 1,
        borderRadius: 1,
        ml: 0.5,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'transparent',
        overflow: 'hidden',
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: '20px',
          width: 0,
          height: 0,
          border: '8px solid transparent',
          borderRightColor: inputColor,
          borderLeft: 0,
          mt: '-8px',
          ml: '-8px'
        },
        '&:has(.Mui-focused)': {
          borderColor: theme.palette.primary.main,
          '&:after': {
            borderRightColor: theme.palette.primary.main,
          },
        },
      }}>
        <TextField
          key={`share-message-${isVisible}`} // This is a hack to force the TextField to re-render when the visibility changes
          placeholder="What’s delicious about this?"
          variant="outlined"
          multiline
          minRows={5}
          fullWidth
          sx={{
            '& .MuiOutlinedInput-root': { padding: 0 },
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          }}
          onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(ev.target.value)}
          value={message}
          inputRef={inputRef}
          data-cy='share-message'
          onKeyDown={handleKeyDown}
        />
        <Box ref={caretDummyRef} sx={{ position: 'absolute', top: 0, left: 0, typography: 'body1', zIndex: -99999, visibility: 'hidden', whiteSpace: 'pre' }}>
          {caretDummyValue}
        </Box>
      </Box>
    </Box>
  );
};
