import PropTypes from 'prop-types';
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';


export function PullerHandle({ width=128, height=5 }) {
  const theme = useTheme();

  return (
    <Box sx={{
      width: width,
      height: height,
      backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[800],
      borderRadius: height/2,
      position: 'absolute',
      top: 8,
      left: `calc(50% - ${width/2}px)`,
    }} />
  );
}

PullerHandle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
