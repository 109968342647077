import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { Link as LinkIcon, Bookmark } from 'react-feather';

import { Link } from './Link';
import { truncate } from '../utils/string';
import { domain, parseInternal } from '../utils/url';

export function TextLink({ textLink, addLink=true }) {

  if (!textLink.unfurl?.link) {
    return textLink.callout;
  }

  const typographySx = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'none' };

  const link = parseInternal(textLink.unfurl.link);

  let linkComponent = null;
  if (link.isInternal && textLink.unfurl.title) {
    const title = truncate(textLink.unfurl.title, 20, true);
    if (link.isCollection) {
      linkComponent = (
        <Box
          component="span"
          sx={typographySx}
        >
          <Bookmark size={14} style={{ verticalAlign: 'middle', marginRight: '2px', marginBottom: '2px' }} />
          {title}
        </Box>
      );
    } else if (link.isCanonical || link.isPost) {
      linkComponent = (
        <Box component="span" sx={typographySx}>+{title}</Box>
      );
    } else if (link.isProfile) {
      linkComponent = (
        <Box component="span" sx={typographySx}>@{title}</Box>
      );
    } else {
      linkComponent = (
        <Box component="span" sx={typographySx}>{title}</Box>
      );
    }
  } else {
    linkComponent = (
      <Box
        component="span"
        sx={typographySx}>
        <LinkIcon size={14} style={{ verticalAlign: 'middle', marginRight: '2px', marginBottom: '2px' }} />
        {domain(textLink.unfurl.link)}
      </Box>
    );
  }

  const sx = {
    ...typographySx,
    borderRadius: '4px',
    color: addLink ? 'primary.main' : 'text.primary',
    display: 'inline-flex',
    maxWidth: '100%',
  };

  if (addLink) {
    return (
      <Link link={textLink.unfurl.link} title={textLink.unfurl.title} sx={sx}>
        {linkComponent}
      </Link>
    );
  } else {
    return (
      <Box component="span" sx={sx}>
        {linkComponent}
      </Box>
    );
  }
}

TextLink.propTypes = {
  textLink: PropTypes.object,
  addLink: PropTypes.bool,
};
