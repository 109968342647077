import PropTypes from 'prop-types';

import { useContact } from '../hooks/use-contact';
import { InternalShareButton } from './InternalShareButton';
import { absolutize } from '../utils/url';
import { canonicalLink } from '../utils/routes';


export function ReshareButton({ share, canonical, ...rest }) {
  const { data } = useContact(share?.sender?._id);
  const sender = data?.contact;

  let message = '';
  if(share?.context?.type === 'public') {
    message = `Via @${sender?.username}: `;
  }

  return (
    <InternalShareButton reshareOf={share?._id} text={message} link={share ? share.message.link : absolutize(canonicalLink(canonical._id)) } origin='post' hidden='link_lists' {...rest} />
  );
}

ReshareButton.propTypes = {
  share: PropTypes.object,
  canonical: PropTypes.object,
};
