import { Box } from "@mui/material";
import { FC } from "react";

import { Image, ProviderItem, Provider as ProviderType } from "../generated/graphql";
import { ProviderLogo } from "./ProviderLogo";


export type ProviderProps = {
  providerItem: Pick<ProviderItem, 'type'> & {
    provider: Pick<ProviderType, '_id' | 'name' | 'color'> & {
      logo?: Pick<Image, 'hash'> | null,
    },
    displayPriority: number,
  },
  selected: boolean,
};


export const Provider: FC<ProviderProps> = function Provider({ providerItem, selected }) {

  return (
    <Box sx={{
      p: '3px 8px',
      backgroundColor: (providerItem.type === 'free' || selected) ? providerItem.provider.color : 'grey.400',
      color: 'white',
      borderRadius: 0.5,
      typography: 'body2',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    }}>
      {providerItem.provider.name}

      {providerItem.provider.logo?.hash &&
        <ProviderLogo
          key={providerItem.provider._id}
          name={providerItem.provider.name}
          logo={providerItem.provider.logo}
          size='tiny'
          sx={{
            flex: '0 0 auto',
            borderRadius: '50%',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'white',
            ml: 1,
            filter: selected ? 'none' : 'grayscale(1)',
            boxSizing: 'content-box',
          }}
        />
      }
    </Box>
  );
};
