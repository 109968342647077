import { CSSProperties, FC, ReactNode } from "react";
import { FadeTransition } from "./FadeTransition";


export type TabPanelProps = {
  value: string,
  index: string,
  style?: CSSProperties,
  children?: ReactNode,
};


export const TabPanel: FC<TabPanelProps> = function TabPanel({ value, index, style, children, ...rest }) {

  const active = value === index;

  return (
    <div
      role="tabpanel"
      style={style}
      id={`tabpanel-${index}`}
      {...rest}
    >
      <FadeTransition match={active}>
        <div>
          {children}
        </div>
      </FadeTransition>
    </div>
  );
}
