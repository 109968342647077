import { FC } from 'react';
import { useContact } from '../hooks/use-contact';
import { LinkBox } from './LinkBox';


export type ContactNameProps = {
  contact: {
    _id: string,
  },
  type?: 'name' | 'username',
  prefix?: string,
  link?: boolean,
};


export const ContactName: FC<ContactNameProps> = function({ contact, type = 'username', prefix, link = false }) {
  const { data, loading, error } = useContact(contact._id);

  if(loading) {
    return '';
  }
  if(error) {
    console.error("Couldn't resolve contact for ContactName", { contact, error });
    return '';
  }

  if(!data?.contact) {
    console.warn("Couldn't resolve contact for ContactName", { contact });
    return 'User not found';
  }

  if(typeof prefix === 'undefined') {
    if(type === 'username') {
      prefix = '@';
    } else {
      prefix = '';
    }
  }

  if(link) {
    return (
      <>
        {prefix}<LinkBox href={`/@${data.contact.username}`}>{data.contact[type]}</LinkBox>
      </>
    );
  }

  return prefix + data.contact[type];
}
