import { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { Button } from './Button';


export type RequestContactsDialogProps = {
  open: boolean,
  onClose: () => void,
  onAllow: () => void,
  onSkip: () => void,
}


export const RequestContactsDialog: FC<RequestContactsDialogProps> = function RequestContactsDialog({ open, onClose, onAllow, onSkip }) {

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant='h2' sx={{ p: 2 }}>
        {"Use contact list?"}
      </DialogTitle>
      <DialogContent sx={{ px: 2, py: 0 }}>
        <DialogContentText sx={{ mb: 1 }}>
          Use your contact list to find friends on the app.
        </DialogContentText>

        <DialogContentText sx={{ mb: 1 }}>
          The contacts will be sent to Ice Cream Club where they will be matched with existing users to find people you know.
        </DialogContentText>

        <DialogContentText sx={{ mb: 1 }}>
          No contact information will be shared with third parties.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 2, py: 1.5 }}>
        <Button onClick={onSkip} variant='text'>
          Not Now
        </Button>
        <Button onClick={onAllow} variant='contained' autoFocus color='primary'>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
