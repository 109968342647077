import { FC, ReactNode } from 'react';
import { List as MuiList, ListProps as MuiListProps } from '@mui/material';


type ListProps = MuiListProps & {
  edgeSwipeGuard?: boolean,
  children: ReactNode,
}

export const List: FC<ListProps> = function List({ edgeSwipeGuard=false, children, sx={}, ...props }) {

  const width = '16px';
  return (
    <MuiList
      sx={{ ...sx, position: 'relative' }}
      {...props}
    >
      {edgeSwipeGuard && <div style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, height: '100%', width }} />}
      {children}
      {edgeSwipeGuard && <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 1, height: '100%', width }} />}
    </MuiList>
  );
}
