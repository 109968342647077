import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Container, SwipeableDrawer } from '@mui/material';
import { PullerHandle } from './PullerHandle';


export const BottomSheetDrawer = memo(function BottomSheetDrawer({ variant='modal', onClose, onOpen, children, top='66px', open=true, disablePadding=false }) {

  if(variant === 'modal') {

    return (
      <SwipeableDrawer
        variant="temporary"
        ModalProps={{disablePortal: false, disableEnforceFocus: true}}
        anchor='bottom'
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        sx={{
          '& .MuiDrawer-paper': {
            top,
            background: 'transparent',
          }
        }}
        disableDiscovery={true}
        disableSwipeToOpen={true}
      >
        <Container
          maxWidth="sm"
          disableGutters={true}
          sx={{
            height: '100%',
            position: 'relative',
          }}
        >
          <Card sx={{
            paddingTop: 2,
            borderRadius: '12px 12px 0 0',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}>

            <PullerHandle />

            <Box sx={{
              paddingLeft: disablePadding ? 0 : 2,
              paddingRight: disablePadding ? 0 : 2,
              maxHeight: '100%',
              overflowY: 'scroll',
              flex: '1 1 auto',
              paddingBottom: 4,
            }}>
              {children}
            </Box>
          </Card>
        </Container>
      </SwipeableDrawer>
    );
  }
});
BottomSheetDrawer.propTypes = {
  variant: PropTypes.oneOf(['modal']),
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  children: PropTypes.any.isRequired,
  top: PropTypes.string,
  open: PropTypes.bool,
  disablePadding: PropTypes.bool,
}
