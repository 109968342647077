import { FC } from "react";
import { Box, Card, PaletteColor, Paper, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Chip } from "./Chip";


const ColorCard: FC<{ color: string }> = function ColorCard({ color }) {
  const theme = useTheme();

  let colorObj: PaletteColor | null = null;

  if(color.startsWith('groups.')) {
    const groupColor = color.split('.')[1];
    if(groupColor in theme.palette.groups) {
      colorObj = theme.palette.groups[groupColor as keyof typeof theme.palette.groups];
    } else {
      throw new Error(`Color ${color} not found in theme.palette.groups`);
    }
  } else if(color in theme.palette) {
    colorObj = theme.palette[color as keyof typeof theme.palette] as PaletteColor;
  }

  if(!colorObj) {
    throw new Error(`Color ${color} not found in theme.palette`);
  }

  return (
    <Card sx={{ backgroundColor: colorObj.background, p: 2, display: 'flex', gap: 1, flexDirection: 'column' }}>
      <Typography variant="h2">{color}</Typography>
      <Chip size='small' label="main" color={colorObj.main} variant='normal' textColor={colorObj.contrastText} onClick={() => null} icon={null} selectedColor={null} sx={{}} />
      <Chip size='small' label="light" color={colorObj.light} variant='normal' textColor={colorObj.contrastText} onClick={() => null} icon={null} selectedColor={null} sx={{}} />
      <Chip size='small' label="dark" color={colorObj.dark} variant='normal' textColor={colorObj.contrastText} onClick={() => null} icon={null} selectedColor={null} sx={{}} />
    </Card>
  );
}



export const Colors: FC = function Colors() {

  return (
    <Paper>
      <Typography variant="h1" sx={{ px: 2, gap: 1 }}>Colors</Typography>

      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', gap: 1, maxWidth: '100%' }}>

        <ColorCard color="primary" />
        <ColorCard color="secondary" />
        <ColorCard color="groups.yellow" />
        <ColorCard color="groups.green" />
        <ColorCard color="groups.blue" />
        <ColorCard color="groups.purple" />
        <ColorCard color="groups.red" />
        <ColorCard color="groups.brown" />
        <ColorCard color="groups.orange" />
        <ColorCard color="groups.public" />
        <ColorCard color="groups.direct" />

      </Box>

    </Paper>
  );
}
