import PropTypes from 'prop-types';

export function TypeYear({ canonical }) {

  const type = {tvshow: 'Series', movie: 'Movie'}[canonical.type];
  let year;

  if(canonical.type === 'movie') {
    year = canonical.movie.year || null;
  } else if(canonical.type === 'tvshow') {
    if(canonical.tvshow.startYear && canonical.tvshow.endYear) {
      year = `${canonical.tvshow.startYear} - ${canonical.tvshow.endYear}`;
    } else if(canonical.tvshow.startYear) {
      year = `${canonical.tvshow.startYear} - `;
    } else {
      year = null;
    }
  } else {
    console.error(`Unknown canonical type: ${canonical.type}`, canonical);
    return null;
  }

  return (
    <>
      {type} {year ? `• ${year}` : ''}
    </>
  );
}
TypeYear.propTypes = {
  canonical: PropTypes.object
};
