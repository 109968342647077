import { FC } from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';


export const TextField: FC<TextFieldProps> = function TextField({ sx, ...props }) {

  return (
    <MuiTextField
      variant="outlined"
      size="small"
      fullWidth
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'grey.50',
          color: 'grey.900'
        },
        ...sx,
      }}
      {...props}
    />
  );
}
