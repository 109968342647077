import { FC } from "react";
import { Route } from "react-router";


export type StatusProps = {
  code: 200 | 404 | 500;
};


export const Status: FC<StatusProps> = function Status({ code }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.statusCode = code;
        return null;
      }}
    />
  );
}
