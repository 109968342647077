import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { NoSsr } from '@mui/material';

import { useSession } from '../hooks/auth';
import { relativize } from '../utils/url';
import { Loading } from './Loading';

export default function PrivateRoute({ children, ...rest }) {

  const { auth, user, loading } = useSession();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        searchParams.delete('token');
        const invitationToken = searchParams.get('invitation_token');
        searchParams.delete('invitation_token');

        let next = searchParams.get('next') ? relativize(searchParams.get('next')) : location.pathname+'?'+searchParams.toString();
        if(token) {
          next += `&token=${token}`;
        }
        if(invitationToken) {
          next += `&invitation_token=${invitationToken}`;
        }

        if(!auth && !loading) {
          console.log(`Not authenticated, redirecting to ${next}`);
          return (
            <NoSsr>
              <Redirect to={next} />
            </NoSsr>
          );
        }
        if(!user) {
          return <Loading />
        }
        return children;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
};
