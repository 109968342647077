import { useState, useEffect, ChangeEvent, FC, KeyboardEvent } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff, KeyboardCapslock } from "@mui/icons-material";

import { useTranslation } from '@/utils/i18n';
import { useAnalyticsQueued } from '@/hooks/delicious-analytics';


export type PasswordInputProps = {
  password: string,
  setPassword: (password: string) => void,
  placeholder?: string,
  register?: boolean,
};


export const PasswordInput: FC<PasswordInputProps> = function PasswordInput({ password, setPassword, placeholder, register = false, ...props }) {

  const { track } = useAnalyticsQueued();
  const { t } = useTranslation('auth');

  const [ focus, setFocus ] = useState(false);
  const [ helper, setHelper ] = useState('');

  const [ showPassword, setShowPassword ] = useState(false);
  const [ capsLock, setCapsLock ] = useState(false);

  useEffect(() => {
    if (password && !focus && !(password.length > 5)) {
      setHelper('Password should be at least 6 characters');
    } else if (!password || !(password.length > 5)) {
      setHelper('');
    }
  }, [ focus, password ]);

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    if(ev.target.value && !password) {
      track('auth_password_input', { category: 'auth', action: 'start_typing' });
    }
    setPassword(ev.target.value);
  }

  const handleFocus = () => {
    if(!focus) {
      track('auth_password_input', { category: 'auth', action: 'focus' });
    }
    setFocus(true);
  }

  return (
    <TextField
      error={!!helper}
      helperText={helper}
      id="password"
      placeholder={placeholder || t('password')}
      type={showPassword ? "text" : "password"}
      fullWidth
      margin="normal"
      size="small"
      variant="filled"
      value={password}
      onChange={handleChange}
      onKeyDown={(ev: KeyboardEvent) => setCapsLock(ev.getModifierState('CapsLock')) }
      onFocus={handleFocus}
      onBlur={ () => setFocus(false) }
      data-cy='login-password'
      autoComplete={register ? "new-password" : "current-password"} // This is important for password managers
      sx={{
        marginTop: 0,
        'input': {
          '&::placeholder': {
            color: 'text.secondary',
            opacity: 1
          }
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton sx={{ p: 0}} onClick={ () => setShowPassword(!showPassword) }>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {capsLock && (
              <IconButton sx={{ p: 0, pl: 1 }}>
                <KeyboardCapslock />
              </IconButton>
            )}
          </InputAdornment>
        )
      }}
      {...props}
    />
  );
}
