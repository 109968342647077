import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { BottomSheetDrawer } from './BottomSheetDrawer';


export function BottomSheet({ variant='modal', onClose, onOpen, children, top, open=true, disablePadding=false }) {

  useEffect(() => {
    if(open) {
      document.body.classList.add('bottom-sheet-open');
    } else {
      document.body.classList.remove('bottom-sheet-open');
    }
    return () => {
      document.body.classList.remove('bottom-sheet-open');
    };
  }, [open]);

  const location = useLocation();
  const locationOnOpen = useRef(location.pathname)

  useEffect(() => {
    if(open) {
      locationOnOpen.current = location.pathname;
    }
  }, [open, location.pathname]);

  useEffect(() => {
    if (locationOnOpen.current !== location.pathname && open) {
      onClose && onClose();
    }
  }, [ location.pathname, open, onClose ]);

  return (
    <BottomSheetDrawer
      variant={variant}
      onClose={onClose}
      onOpen={onOpen}
      top={top}
      open={open}
      disablePadding={disablePadding}
    >
      {children}
    </BottomSheetDrawer>
  );
}
BottomSheet.propTypes = {
  variant: PropTypes.oneOf(['modal']),
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  children: PropTypes.any.isRequired,
  top: PropTypes.string,
  open: PropTypes.bool,
  disablePadding: PropTypes.bool,
}
