import { createElement, ComponentType, FC, ReactNode } from 'react';
import { ButtonBase, ButtonBaseProps, SxProps } from '@mui/material';
import { useNavigationActions } from '../hooks/history';


export type BackButtonProps = ButtonBaseProps & {
  component?: ComponentType<{ onClick?: () => void }>,
  children: ReactNode,
  sx?: SxProps,
  size?: 'small' | 'medium' | 'large'
}


export const BackButton: FC<BackButtonProps> = function BackButton({ component, children, ...props }) {

  const { goBackOrHome } = useNavigationActions();

  return createElement(component || ButtonBase, { title: 'Back', ...props, onClick: goBackOrHome }, children);
}
