import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import { Button } from './Button';
import { useHistory } from 'react-router-dom';
import { ErrorLogo, ErrorIceCream } from './icons';

export function Error({ error }) {
  const history = useHistory();
  const reload = () => {
    history.go(0);
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: '100%',
        backgroundColor: '#FAADA8',
        position: 'relative',
        padding: '64px 32px',
      }}
      maxWidth="sm"
    >
      <ErrorLogo sx={{ width: '110px', height: '50px' }} />
      <Typography variant="h2" style={{
        marginTop: 64,
        marginBottom: 16,
        whiteSpace: 'pre-line',
        lineHeight: 1.4,
        fontSize: '36px',
        color: '#C3362B',
        textAlign: 'center',
      }}>
        Oh no!
      </Typography>
      <Typography variant="h3" style={{
        marginBottom: 16,
        whiteSpace: 'pre-line',
        lineHeight: 1.4,
        color: '#621B16',
        textAlign: 'center',
      }}>
        Something went wrong.
      </Typography>
      <Typography variant="subtitle1" style={{
        marginBottom: 64,
        whiteSpace: 'pre-line',
        lineHeight: 1.4,
        color: '#621B16',
        textAlign: 'center',
      }}>
        {error.message}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        sx={{ mb: 4, color: '#FFF', backgroundColor: '#C3362B' }}
        onClick={ reload }
      >
        Try again
      </Button>
      <ErrorIceCream sx={{ width: '208px', height: '187px', bottom: '64px' }} />
    </Container>
  );
}

Error.propTypes = {
  error: PropTypes.object.isRequired,
};
