import { FC } from "react";
import { CanonicalContent, Movie, TvShow } from "../../generated/graphql";


export type YearProps = {
  canonical: Pick<CanonicalContent, 'type'> & {
    movie?: Pick<Movie, 'year'> | null,
    tvshow?: Pick<TvShow, 'startYear' | 'endYear'> | null,
  },
};


export const Year: FC<YearProps> = function({ canonical }) {

  let year = '';

  if(canonical.type === 'movie' && canonical.movie) {
    year = canonical.movie.year?.toString() || '';
  } else if(canonical.type === 'tvshow' && canonical.tvshow) {
    if(canonical.tvshow.startYear && canonical.tvshow.endYear) {
      year = `${canonical.tvshow.startYear} - ${canonical.tvshow.endYear}`;
    } else if(canonical.tvshow.startYear) {
      year = `${canonical.tvshow.startYear} - `;
    } else {
      year = '';
    }
  } else {
    console.error(`Unknown canonical type: ${canonical.type}`, canonical);
    return null;
  }

  return year;
}
