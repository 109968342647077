import { FC, SyntheticEvent, useState } from 'react';
import { Box, Divider, SxProps, Typography } from '@mui/material';

import { CanonicalContent, Movie, Share, TvShow, Image as ImageType, Provider as ProviderType, Season } from '../generated/graphql';
import { useSession } from '../hooks/auth';
import { useIsInWatchlist, useIsWatched, useToggleInWatchlist, useToggleWatched } from '../hooks/watch-state';
import { useAnalyticsQueued } from '../hooks/delicious-analytics';
import { canonicalLink } from '../utils/routes';
import { AvatarList } from './AvatarList';
import { Image } from './Image';
import { LinkBox } from './LinkBox';
import { Year } from './canonical/Year';
import { ProviderLogo } from './ProviderLogo';
import { AddCircle, AddCircleFilled } from './icons';
import { Watched } from './icons/Watched';
import { WatchedSeasonsDialog, WatchedSeasonsDialogProps } from './canonical/WatchedSeasonsDialog';
import { RatingPopover, RatingPopoverProps } from './RatingPopover';
import { RatingIcon } from './icons/RatingIcon';
import { Title } from './canonical/Title';


export type PosterCardProps = {
  share?: Pick<Share, '_id' | 'unfurl'> & {
    rating?: RatingPopoverProps['rating'],
  } | null,
  canonicalContent?: Pick<CanonicalContent, '_id' | 'watchState' | 'type'> & {
    movie?: Pick<Movie, 'title' | 'year'> & {
      poster?: Pick<ImageType, 'hash'> | null,
      providers?: {
        type: string,
        provider: Pick<ProviderType, '_id' | 'name'> & {
          logo?: Pick<ImageType, 'hash'> | null,
        },
      }[],
    } | null,
    tvshow?: Pick<TvShow, 'title' | 'startYear' | 'endYear'> & {
      poster?: Pick<ImageType, 'hash'> | null,
      providers?: {
        type: string,
        provider: Pick<ProviderType, '_id' | 'name'> & {
          logo?: Pick<ImageType, 'hash'> | null,
        },
      }[],
      seasons?: Pick<Season, 'seasonNumber'>[] & WatchedSeasonsDialogProps['seasons'] | null,
    } | null
    rating?: RatingPopoverProps['rating'],
  },
  width?: number,
  height?: number,
  sx?: SxProps,
  loading?: 'eager' | 'lazy',
  showTitle?: boolean,
  showProvider?: boolean,
  showButtons?: boolean,
  trackingCategory: string,
}

export const PosterCard: FC<PosterCardProps> = function PosterCard({ share, canonicalContent, trackingCategory, width=136, height=200, sx={}, loading='eager', showTitle=false, showProvider=false, showButtons=false, ...props }) {

  const { user } = useSession();
  const { track } = useAnalyticsQueued();

  const [watchedSeasonsDialogOpen, setWatchedSeasonsDialogOpen] = useState(false);
  const [ratingPopoverOpen, setRatingPopoverOpen] = useState(false);

  const rating = canonicalContent?.rating || share?.rating;

  const inWatchlist = useIsInWatchlist(canonicalContent?._id, share?._id);
  const watchlistBy = canonicalContent?.watchState?.watchlistBy || [];
  const watched = useIsWatched(share?._id, canonicalContent?._id);
  const watchedBy = canonicalContent?.watchState?.watchedBy || [];

  const toggleWatchlist = useToggleInWatchlist();
  const { toggleWatched, toggleWatchedSeasons } = useToggleWatched();

  const handleWatchlistClick = (ev: SyntheticEvent, canonicalId: string | undefined, shareId: string | undefined, active: boolean) => {
    ev.preventDefault();
    ev.stopPropagation();

    track('toggle_watchlist_button', { category: trackingCategory });
    toggleWatchlist(canonicalId, shareId, active);
  };

  const handleWatched = (event: SyntheticEvent, active: boolean, createFeedItems=true) => {
    event.preventDefault();
    event.stopPropagation();

    track('click_watched_button', { category: 'watch-state', origin: 'poster-card' });
    // Show season popup if canonical is tvshow with multiple seasons
    const seasons = canonicalContent?.tvshow?.seasons;
    if(canonicalContent?.type === 'tvshow') {
      if(seasons && seasons.length === 1) {
        toggleWatchedSeasons(canonicalContent._id, [seasons[0].seasonNumber], active, createFeedItems);
      } else if (seasons && seasons.length > 1) {
        setWatchedSeasonsDialogOpen(true);
        return; // skip open rating popover
      } else {
        toggleWatched(canonicalContent?._id, share?._id, active, createFeedItems);
      }
    } else {
      toggleWatched(canonicalContent?._id, share?._id, active, createFeedItems);
    }
    if(active && !rating?.rating) {
      setRatingPopoverOpen(true);
    }
  }

  const handleWatchedSeasons = (event: SyntheticEvent, seasonNumbers: number[], active: boolean, createFeedItems=true) => {
    event.preventDefault();
    event.stopPropagation();
    if(!canonicalContent?.tvshow) {
      console.error("Can't toggle watched seasons without tvshow canonical", { canonicalId: canonicalContent?._id, shareId: share?._id, seasonNumbers, active });
      throw new Error(`Can't toggle watched seasons without tvshow canonical`);
    }
    track('click_watched_season', { category: 'watch-state', origin: 'poster-card', canonicalId: canonicalContent._id, shareId: active, seasonNumbers: seasonNumbers.join(',') });
    toggleWatchedSeasons(canonicalContent._id, seasonNumbers, active, createFeedItems);
  }


  const contacts: string[] = [...watchedBy, ...watchlistBy, inWatchlist && user?._id, watched && user?._id].filter((contactId, index, arr) => contactId && arr.indexOf(contactId) === index) as string[];

  const image = (canonicalContent?.type === 'movie' ? canonicalContent?.movie?.poster : canonicalContent?.tvshow?.poster) || share?.unfurl?.images?.[0];

  const userProviders: Pick<ProviderType, '_id'>[] = user?.providers || [];
  const canonicalProviders = canonicalContent?.[canonicalContent?.type === 'movie' ? 'movie' : 'tvshow']?.providers || [];
  const selectedProviders = canonicalProviders.filter(providerItem => userProviders.some(p => p._id === providerItem.provider._id));
  const freeProviders = canonicalProviders.filter(providerItem => providerItem.type === 'free');
  const providerItem = showProvider ? selectedProviders?.[0] || freeProviders?.[0] : undefined;

  if(!image) {
    return null;
  }

  return (
    <LinkBox
      key={canonicalContent?._id || share?._id}
      href={canonicalLink(canonicalContent?._id, share?._id)}
      sx={{ /*boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.4)',*/ borderRadius: '4px', position: 'relative', width, overflow: 'hidden', display: 'block', backgroundColor: 'grey.100', ...sx }}
      {...props}
    >
      <Box sx={{ position: 'relative' }}>
        <Image
          {...image}
          constraints={`${width*2}x${height*2}_c`}
          width={width}
          height={height}
          loading={loading}
          alt={canonicalContent?.movie?.title || canonicalContent?.tvshow?.title || share?.unfurl?.title || ''}
        />

        {providerItem?.provider?.logo?.hash &&
          <Box sx={{ position: 'absolute', top: '8px', left: '8px', zIndex: 1, pb: 2 }}>
            <ProviderLogo
              key={providerItem.provider._id}
              name={providerItem.provider.name}
              logo={providerItem.provider.logo}
              size='tiny'
              sx={{
                flex: '0 0 auto',
                borderRadius: '50%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'white',
                boxSizing: 'content-box',
              }}
            />
          </Box>
        }

        {contacts.length > 0 &&
          <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: 8,
            zIndex: 1,
          }}>
            <Box sx={{ display: 'flex', borderRadius: '999px', backdropFilter: 'blur(3px) contrast(0.7)', p: 0.5, px: 0.5, backgroundColor: 'rgba(255, 255, 255, 0.3)', lineHeight: 0, mb: 1, width: 'fit-content' }}>
              <AvatarList contacts={contacts} size="xsmall" sx={{ borderWidth: '1.5px', pl: 0 }} />
            </Box>
          </Box>
        }
      </Box>

      {showButtons &&
        <>
          <Image
            hash={image.hash}
            constraints={`${width*2}x${height*2}_c`}
            width={width} height={height}
            alt=''
            style={{
              position: 'absolute',
              zIndex: 0,
              transform: 'rotate(180deg) scaleX(-1)',
              objectPosition: 'bottom',
            }}
          />
          <Box sx={{ position: 'relative', display: 'flex', width: '100%', zIndex: 2, backgroundColor: 'transparent', backgroundImage: 'linear-gradient(rgba(0,0,0,0.001), rgba(0,0,0,0.7), rgba(0,0,0,1.0))', justifyContent: 'space-evenly', py: 1, mt: -1 }}>
            <Box
              sx={{ typography: 'body2', mt: 0.5, color: 'white', minWidth: 'auto', backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}
              color='grey'
              onClick={ev => handleWatchlistClick(ev, canonicalContent?._id, share?._id, !inWatchlist)}
            >
              {inWatchlist ?
                (<AddCircleFilled sx={{ width: 14, height: 14, color: 'primary.main', mb: '1px' }} />) :
                (<AddCircle sx={{ width: 14, height: 14, color: 'white', strokeWidth: 2, mb: '1px' }} />)
              }
              <span style={{ marginLeft: '4px' }}>Watchlist</span>
            </Box>

            <Divider orientation='vertical' flexItem sx={{ mt: '6px', mb: '2px', borderColor: 'white' }} />

            <Box
              sx={{ typography: 'body2', mt: 0.5, color: 'white', minWidth: 'auto', backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}
              color='grey'
              onClick={ev => handleWatched(ev, !watched)}
            >
              {rating?.rating &&
                <RatingIcon value={rating.rating} highlight={true} style={{ width: 14, height: 14, marginBottom: '1px' }} />
              }
              {!rating?.rating &&
                <Watched isWatched={watched} unselectedColor='white' sx={{ width: 14, height: 14, mb: '1px' }} />
              }
              <span style={{ marginLeft: '4px' }}>Seen</span>
            </Box>
          </Box>

        </>
      }

      <Box sx={{ px: 1, py: 0.5, display: !showTitle ? 'none' : undefined }}>
        <Typography noWrap variant='body2'>
          {canonicalContent &&
            <Title canonical={canonicalContent} />
          }
          {!canonicalContent &&
            share?.unfurl?.title || ''
          }
        </Typography>
        <Typography noWrap variant='body2' color='text.secondary'>
          {canonicalContent &&
            <Year canonical={canonicalContent} />
          }
        </Typography>
      </Box>

      {canonicalContent?.tvshow?.seasons &&
        <WatchedSeasonsDialog canonicalId={canonicalContent._id} open={watchedSeasonsDialogOpen} onClose={() => setWatchedSeasonsDialogOpen(false)} handleWatchedSeason={handleWatchedSeasons} seasons={canonicalContent.tvshow.seasons} />
      }

      {ratingPopoverOpen &&
        <RatingPopover
          shareId={share?._id}
          canonicalContentId={canonicalContent?._id}
          open={ratingPopoverOpen}
          onClose={() => setRatingPopoverOpen(false)}
          rating={rating}
          isWatched={watched}
          handleWatched={() => null}
        />
      }

    </LinkBox>
  );
}
