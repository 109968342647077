import { SxProps } from '@mui/material';
import { FC, useMemo } from 'react';

import { useSession } from '@/hooks/auth';
import { Avatar, AvatarProps } from './Avatar';
import { AvatarGroup } from './AvatarGroup';


export const AvatarList: FC<{ contacts: AvatarProps["contact"][], size: AvatarProps["size"], max?: number, sx?: SxProps, avatarSx?: SxProps }> = function AvatarList({ contacts, size='medium', max=5, sx={}, avatarSx={}, ...rest }) {

  const { user } = useSession();

  const sorted = useMemo(() => {
    const contactsIds = contacts.filter(Boolean).map(c => typeof c === 'string' ? c : c?._id) as string[];
    if(!user) {
      return contactsIds;
    }
    const userContacts = user.contacts?.map(c => c._id) || [];
    return contactsIds.sort((a, b) => {
      const ia = userContacts.indexOf(a);
      const ib = userContacts.indexOf(b);
      if(ia === -1 && ib === -1) {
        return 0;
      } else if(ia === -1) {
        return 1;
      } else if(ib === -1) {
        return -1;
      } else {
        return ia - ib;
      }
    });
  }, [contacts, user]);


  return (
    <AvatarGroup max={max} spacing={5} size={size} sx={{ display: 'inline-flex', pl: '5px', ...sx }} {...rest} >
      {sorted.map(id =>
        <Avatar size={size} key={id} contact={id} sx={avatarSx} />
      )}
    </AvatarGroup>
  );
}
