import { FC } from 'react';
import { Box } from '@mui/material';

import { Avatar, AvatarProps } from './Avatar';


type AvatarStackProps = {
  contacts: AvatarProps['contact'][],
  size?: 'small' | 'medium',
  borderColor?: string,
} & Omit<AvatarProps, 'contact' | 'size'>


export const AvatarStack: FC<AvatarStackProps> = function AvatarStack({ contacts, size='medium', borderColor='white', ...rest }) {

  let containerSize = 40;
  let avatarSize = 24;
  let borderWidth = 3;
  let fontSize = '14px';

  if(size === 'small') {
    containerSize = 30;
    avatarSize = 20;
    borderWidth = 2;
    fontSize = '14px';
  }

  if(contacts.length === 1) {
    return (
      <Avatar
        {...rest}
        contact={contacts[0]}
        size={size}
        sx={{
          width: containerSize,
          height: containerSize,
          fontSize: 18,
          ...rest.sx,
        }}
      />
    )
  }

  return (
    <Box
      {...rest}
      sx={{
        position: 'relative',
        width: containerSize + 2 * borderWidth,
        height: containerSize + 2 * borderWidth,
        margin: -1 * borderWidth + 'px',
        flex: '0 0 auto',
        ...rest.sx,
      }}
    >
      <Avatar
        contact={contacts[0]}
        sx={{
          width: avatarSize + 2 * borderWidth,
          height: avatarSize + 2 * borderWidth,
          fontSize,
          borderWidth,
          borderColor,
          borderStyle: 'solid',
          position: 'absolute',
          top: 0,
          left: 0,
          '&&&.MuiAvatar-root': {
            borderWidth,
          }
        }}
      />

      <Avatar
        contact={contacts[1]}
        sx={{
          width: avatarSize + 2 * borderWidth,
          height: avatarSize + 2 * borderWidth,
          fontSize,
          borderWidth,
          borderColor,
          borderStyle: 'solid',
          position: 'absolute',
          bottom: 0,
          right: 0,
          '&&&.MuiAvatar-root': {
            borderWidth,
          }
        }}
      />
    </Box>
  );
}
