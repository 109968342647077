import PropTypes from 'prop-types';
import { Badge, Chip as MuiChip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Check } from 'react-feather';


export function Chip({ variant, label, icon, color, selectedColor, textColor, onClick, sx, size='medium', touchLabelProps={}, badgeContent=0, ...rest }) {

  const theme = useTheme();

  const selectedIcon = variant === 'selected' ? <Check /> : null;
  const boxShadow = ['selectable', 'selected'].includes(variant);
  const stateColor = variant === 'selected' && selectedColor ? selectedColor : color;

  return (
    <label {...touchLabelProps} style={{ paddingTop: 12, paddingBottom: 12, marginTop: -12, marginBottom: -12, display: 'flex', ...touchLabelProps.style }}>
      <Badge
        badgeContent={badgeContent}
        variant='dot'
        color='primary'
        overlap='rectangular'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiBadge-anchorOriginTopRightRectangular': {
            transform: 'scale(1) translate(30%, -20%)',
          },
          '& .MuiBadge-dot': {
            border: '2px solid white',
            borderRadius: 6,
            width: 12,
            height: 12,
          },
          '& .MuiBadge-invisible': {
            transform: 'scale(0) translate(30%, -20%)',
          },
        }}
      >
        <MuiChip
          component='button'
          ref={rest.fref}
          variant='filled'
          size={size}
          icon={selectedIcon}
          deleteIcon={icon}
          sx={{
            overflow: 'hidden',
            fontWeight: 500,
            fontSize: '16px',
            color: textColor,
            backgroundColor: stateColor,
            border: 'none',
            boxShadow: boxShadow ? theme.shadows[0] : 'none',
            '&& .MuiChip-label': {
              paddingLeft: label ? '' : 0
            },
            '&& .MuiChip-deleteIcon': {
              height: 16,
              marginRight: 1,
              fill: 'none',
              display: icon ? 'initial' : 'none'
            },
            '&& .MuiChip-icon': {
              height: 16,
              width: 16,
              stroke: textColor
            },
            ...sx,
          }}
          label={label}
          onDelete={icon ? onClick: null}
          onClick={onClick}
          clickable={false}
          {...rest}
        />
      </Badge>
    </label>
  );
}
Chip.propTypes = {
  variant: PropTypes.oneOf(['normal', 'selectable', 'selected']).isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  color: PropTypes.string.isRequired,
  selectedColor: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
  boxShadow: PropTypes.bool,
  touchLabelProps: PropTypes.object,
  badgeContent: PropTypes.any,
}
