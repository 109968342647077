import { FC, MouseEvent, ReactNode, useCallback } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { createContactType } from '@/utils/json-ld';
import { useContact } from '../hooks/use-contact';
import { Avatar, AvatarProps } from './Avatar';
import { Helmet } from './Helmet';


export interface UserProps {
  userId?: string,
  username?: string,
  addLink?: boolean,
  size?: AvatarProps["size"],
  postfix?: ReactNode,
  sx?: SxProps & { typography?: SxProps },
  avatar?: boolean,
}

export const User: FC<UserProps> = function User({ userId, addLink = true, size='medium', postfix, sx={}, avatar = true }) {

  const history = useHistory();

  const { data, loading, error } = useContact(userId);

  const user = data?.contact;

  if(error) {
    console.error("Couldn't resolve contact in User", { userId, error });
  }

  const { typography: sxTypography, ...sxRest } = sx;

  const url = user ? `/@${user.username}` : `/profile-id/${userId}`;

  const handleClick = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    history.push(url);
  }, [history, url]);

  if(loading || error) {
    return null;
  }

  if(!user) {
    console.warn("Couldn't resolve contact for User", { userId });
    return (
      <Typography variant={size === 'small' ? 'h4' : 'button'} noWrap sx={sxTypography}>
        User not found
      </Typography>
    );
  }

  const jsonLd = createContactType(user);

  const userComponent = (
    <>
      <Helmet>
        <script type="application/ld+json">{jsonLd && JSON.stringify(jsonLd)}</script>
      </Helmet>
      {avatar &&
        <Avatar
          sx={{ mr: '6px' }}
          size={size}
          contact={user}
        />
      }
      <Typography variant={size === 'small' ? 'h4' : 'button'} noWrap sx={sxTypography}>
        {user.username}{postfix}
      </Typography>
    </>
  );

  const style: SxProps = {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    margin: '-8px 0',
    padding: '8px 0',
    ...sxRest,
  };

  if(addLink) {
    return (
      <Box component='a' sx={style} href={url} onClick={handleClick}>
        {userComponent}
      </Box>
    );
  }

  return (
    <Box sx={style}>
      {userComponent}
    </Box>
  );
}
