import { Box, NoSsr, Tooltip, Typography } from "@mui/material";
import { FC, memo } from "react";

import { Contact, Context, Rating, Share } from "../../generated/graphql";
import { formatAge } from "../../utils/time";
import { RatingIcon } from "../icons/RatingIcon";
import { User } from "../User";
import { FollowButton } from "../FollowButton";
import { MoreButton, MoreButtonProps } from "./MoreButton";
import { ShareContext, ShareContextProps } from "../ShareContext";


export type HeaderProps = {
  share: Pick<Share, 'createdAt'> & {
    sender?: Pick<Contact, '_id'> | null,
    sendersRating?: Pick<Rating, 'rating'> | null,
    context?: Pick<Context, 'type'> & ShareContextProps['context'] | null,
  } & MoreButtonProps['share'],
}


export const Header: FC<HeaderProps> = memo(function Header({ share }: HeaderProps) {

  const sharedWith = () => {
    if(share.context?.type === 'group') {
      return <ShareContext context={share.context} />;
    } else if(share.context?.type === 'public-list'){
      return (
        <Typography variant='h4' noWrap sx={{ display: 'inline', backgroundColor: 'primary.background', color: 'text.secondary', borderRadius: 0.5, px: 0.5 }}>
          To collection
        </Typography>
      );
    } else {
      return '';
    }
  }

  return (
    <Box sx={{ ml: 2, mr: 0.5, mt: 1, display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, overflow: 'hidden' }}>
        {share.sender &&
          <>
            <User sx={{ flex: '0 0 auto', overflow: 'hidden' }} userId={share.sender._id} size='medium' postfix={share.context?.type === 'public-list' ? ' saved' : ''} />
            {share.sendersRating &&
              <RatingIcon value={share.sendersRating.rating} highlight />
            }
            {sharedWith()}
            <FollowButton contact={share.sender} lineHeight='20px' divider={false} />
          </>
        }
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={<NoSsr>{new Date(share.createdAt).toLocaleString()}</NoSsr>}>
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {formatAge(new Date(share.createdAt), 'short')}
          </Typography>
        </Tooltip>
        <MoreButton share={share} />
      </Box>
    </Box>
  );
});
