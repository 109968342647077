import { Box, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { FC, SyntheticEvent, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { useProvidersByCountryQuery, useSelectProviderMutation, Provider } from "../generated/graphql";
import { useSession } from "../hooks/auth";
import { useAnalyticsQueued } from "../hooks/delicious-analytics";
import { useLoginPush } from "../hooks/login-push";
import { countriesByCode } from "../utils/countries";
import { AntSwitch } from "./AntSwitch";
import { BottomSheet } from "./BottomSheet";
import { ProviderLogo } from "./ProviderLogo";


export const ProvidersSheet: FC<{ open: boolean, onClose: (ev?: SyntheticEvent) => void, country: string }> = function ProvidersSheet({ open, onClose, country }) {

  const { user } = useSession();
  const { track } = useAnalyticsQueued();
  const { setLoginHint } = useLoginPush();
  const history = useHistory();


  const { data, loading, error } = useProvidersByCountryQuery({ variables: { country: country }, ssr: false });

  if(error) {
    console.error(error);
  }

  const [selectProvider, { error: selectError, loading: selectLoading }] = useSelectProviderMutation();

  if (selectError) {
    console.error(selectError);
  }


  const providersList = useMemo(() => {
    if(!data?.providers) {
      return [];
    }

    const userProviders = (user?.providers || []) as Provider[];

    const onlySubscriptions = data.providers.filter(p => p.types?.includes('flatrate'));
    const sortedProviders = onlySubscriptions.sort((a, b) => Number(a.displayPriority) - Number(b.displayPriority));

    const withSelected = [];
    for (const provider of sortedProviders) {
      if(userProviders.some(up => up._id === provider._id)) {
        withSelected.push({ ...provider, selected: true });
      } else {
        withSelected.push({ ...provider, selected: false });
      }
    }
    return withSelected;

  }, [data, user?.providers]);


  const freeProvidersList = useMemo(() => {
    if(!data?.providers) {
      return [];
    }

    const onlyFree = data.providers.filter(p => p.types.length === 1 && p.types?.includes('free'));
    const sortedProviders = onlyFree.sort((a, b) => Number(a.displayPriority) - Number(b.displayPriority));

    return sortedProviders;
  }, [data]);


  const handleClickCountry = () => {
    if(!user) {
      setLoginHint('You need to be logged in to change the country.');
      track('click_change_country', { category: 'not-logged-in' });
    } else {
      track('click_change_country', { category: 'providers-sheet' });
      history.push(`/profile/settings`);
    }
  };


  const handleSelectProvider = (providerId: string, selected: boolean) => {
    if(!user) {
      setLoginHint('You need to be logged in to save your streaming services.');
      track('select_provider', { category: 'not-logged-in' });
    } else {
      if(!selectLoading) {
        selectProvider({
          variables: {
            input: { _id: providerId, selected: selected }
          },
          optimisticResponse: {
            selectProvider: {
              __typename: 'Person',
              _id: user._id,
              providers: [
                ...providersList.filter(p => p.selected).filter(p => p._id !== providerId).map(p => ({ __typename: 'Provider', _id: p._id })),
                ...(selected ? [{ __typename: 'Provider', _id: providerId }] : [])
              ] as Pick<Provider, '__typename' | '_id'>[],
            }
          }
        });
        track('click_select_provider', { category: 'user-settings' });
      }
    }
  };


  return (

    <BottomSheet open={open} onClose={onClose} onOpen={() => null}>
      {loading &&
        <Typography variant='body2'>Loading...</Typography>
      }
      <List>
        <ListItem sx={{ px: 0, pb: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h2">
            Your streaming services
          </Typography>
          <Box onClick={handleClickCountry}>
            {countriesByCode[country].emoji}
          </Box>
        </ListItem>
        {open &&
          <>
            {providersList.map(provider =>
              <ListItem key={provider.name} sx={{ px: 0 }}>
                <ListItemAvatar>
                  {provider.logo &&
                    <ProviderLogo name={provider.name} logo={provider.logo} />
                  }
                </ListItemAvatar>
                <ListItemText
                  primary={provider.name}
                />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  <AntSwitch checked={provider.selected} onChange={() => handleSelectProvider(provider._id, !provider.selected)} />
                </ListItemSecondaryAction>
              </ListItem>
            )}

            <ListItem sx={{ px: 0, pb: 1, display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h2">
                Free services
              </Typography>
            </ListItem>
            {freeProvidersList.map(provider =>
              <ListItem key={provider.name} sx={{ px: 0 }}>
                <ListItemAvatar>
                  {provider.logo &&
                    <ProviderLogo name={provider.name} logo={provider.logo} />
                  }
                </ListItemAvatar>
                <ListItemText
                  primary={provider.name}
                />
                <ListItemSecondaryAction sx={{ right: 0 }}>
                  <AntSwitch checked={true} disabled />
                </ListItemSecondaryAction>
              </ListItem>
            )}

            <ListItem sx={{ px: 0, pt: 4 }}>
              <Typography variant="body2" color='text.secondary'>
                Powered by JustWatch
              </Typography>
            </ListItem>
          </>
        }
      </List>
    </BottomSheet>
  );
}
