import { FC, useCallback, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { useLocation } from "react-router-dom";
import { Typography, Alert, Fab, AlertColor, AlertPropsColorOverrides } from "@mui/material";
import { useSnackbar } from 'notistack';
import { FastForward } from '@mui/icons-material';
import { motion, AnimatePresence } from "framer-motion";
import type { OverridableStringUnion } from '@mui/types';

import { loadLanguageBundle } from "../../utils/i18n";
import { useSession } from "../../hooks/auth";
import { useLocationCount } from "../../hooks/history";
import { useLoginPush } from "../../hooks/login-push";
import { Login } from "./Login";
import { Welcome } from './Welcome';

import language from '../auth/language.json';
loadLanguageBundle('auth', language);


export const NotLoggedInContainer: FC = function NotLoggedInContainer() {

  const { loading, initializing, auth } = useSession();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const locationCount = useLocationCount();

  const { displayState, setDisplayState, hasShown, loginHint, setLoginHint } = useLoginPush();

  const isApp = Capacitor.getPlatform() !== 'web' || location.search.includes('force-app');

  useEffect(() => {
    if(loginHint) {
      setLoginHint('');
    }
  }, [ location, loginHint, setLoginHint ]);

  useEffect(() => {
    if(locationCount.push + locationCount.pop >= 4 && !hasShown && !auth) {
      setDisplayState('show');
    }
  }, [ locationCount, hasShown, setDisplayState, auth ]);

  const showSnackbar = useCallback((message: string, severity?: AlertColor, color?: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>) => {
    enqueueSnackbar(message, {
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      content: (key, message) => (
        <Alert variant="filled" color={color || 'secondary'} severity={severity || 'info'} key={key} sx={{ '& .MuiAlert-icon': { pt: '10px' }}}>
          <Typography variant="body1">{message}</Typography>
        </Alert>
      )
    });
  }, [ enqueueSnackbar ]);

  useEffect(() => {
    if(loginHint && !auth) {
      setDisplayState('show');
      const timeoutHandle = setTimeout(() => setLoginHint(''), 1000);
      showSnackbar(loginHint);
      return () => {
        setLoginHint('');
        clearTimeout(timeoutHandle);
      };
    }
  }, [ loginHint, setLoginHint, showSnackbar, setDisplayState, auth ]);

  if(loading || initializing || auth) {
    return null;
  }

  // Don't login fab on auth pages
  const isAuthPage = location.pathname.startsWith('/auth');

  const fabRadius = '20px';
  const fabCenterOffset = `calc(100% - 60px)`;

  return (
    <AnimatePresence>
      {(displayState === 'initial' || displayState === 'hidden') && !isAuthPage &&
        <Fab
          color="secondary"
          size="medium"
          variant="extended"
          onClick={() => setDisplayState('show')}
          sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: 1000, boxShadow: 3 }}

          component={motion.div}
          key='not-logged-in-fab'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <FastForward sx={{mr:1}} />Login
        </Fab>
      }
      {displayState === 'show' && !isAuthPage &&
        <Login fabRadius={fabRadius} fabCenterOffset={fabCenterOffset} />
      }
      {displayState === 'initial' && isApp &&
        <Welcome fabRadius={fabRadius} fabCenterOffset={fabCenterOffset} />
      }
    </AnimatePresence>

  );
}
