import PropTypes from 'prop-types';
import { ErrorBoundary as SentryErrorBoundary } from "../utils/sentry";
import { Error } from './Error';


export function ErrorBoundary({ children }) {
  return (
    <SentryErrorBoundary fallback={({ error }) => (
      <Error error={error} />
    )}>
      {children}
    </SentryErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.any.isRequired,
};
