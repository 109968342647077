import { FC, ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Box, Typography, SxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Contact, Context as ContextType, Group, useGetGroupsQuery } from '../../generated/graphql';
import { useSession } from '../../hooks/auth';
import { Globe } from '../icons/Globe';
import { DotChip } from '../DotChip';
import { Person } from './Person';
import { AvatarStack } from '../AvatarStack';
import { ContactName } from '../ContactName';
import { ProfileLink } from '../ProfileLink';


export type ContextProps = {
  from: Pick<Contact, '_id'>,
  context: Pick<ContextType, 'type'> & {
    group?: Pick<Group, '_id' | 'name' | 'color'> | null,
    people?: Array<Pick<Contact, '_id'>> | null,
  },
  sx?: SxProps & {
    typography?: SxProps,
  } | null,
  onClick?: () => void,
  size?: 'small' | 'medium',
  color?: string,
};

export const Context: FC<ContextProps> = function Context({ from, context, sx={}, onClick, size='medium', color, ...rest }) {

  const theme = useTheme();
  const history = useHistory();
  const { auth } = useSession();

  const { typography={}, ...restSx } = sx || {};

  const { data, error, refetch } = useGetGroupsQuery({
    skip: !context.group || !auth,
  });

  if(error) {
    console.log("Couldn't fetch groups in Context, ignoring error", { errorStr: error.toString(), error, context });
  }

  let group;
  if(context?.group?._id) {
    if(data?.currentUser?.groups) {
      const groupId = context.group._id.split('@')[0];
      group = data.currentUser.groups.find(g => g._id === groupId);
    }
    if(!group) {
      group = { ...context.group, members: [] };
      refetch();
    }
  }

  let type = context?.type;
  if (!type) {
    // @todo - fix root cause https://www.notion.so/icecreamclub/Data-bug-ccaa7ddbf6d140ed96d7f51411f14065
    type = 'public';
  }

  if(type === 'people' && context.people && context.people.filter(person => person._id !== from?._id).length === 1) {

    const person = context.people.filter(person => person._id !== from?._id)[0];
    return <Person contactId={person._id} onClick={onClick} size={size} sx={sx} {...rest} />

  } else if(type === 'people' && context.people) {

    const people = context.people.filter(p => p._id !== from?._id);
    const memberNames = people.map(p => <ContactName key={p._id} contact={p} type='username' prefix='' />);
    const memberNamesJoined = memberNames.reduce((prev, curr) => prev.length === 0 ? [curr] : [...prev, ', ', curr], [] as (ReactNode | string)[]);

    if(size === 'small') {
      return (
        <Box sx={{ lineHeight: '1.3em', display: 'flex', alignItems: 'center', ...restSx }} {...rest}>
          <Typography variant='body2' noWrap sx={{ ...typography }}>
            {memberNamesJoined}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={{ lineHeight: '1.3em', display: 'flex', flexFlow: 'wrap', gap: '8px', marginTop: '-4px', marginBottom: '-4px', ...restSx }} {...rest}>
          {context.people.filter(person => person._id !== from?._id).map(person =>
            <Person key={person._id} contactId={person._id} onClick={onClick} size={size} sx={{ marginTop: '4px', marginBottom: '4px', ...restSx }} />
          )}
        </Box>
      );
    }

  } else if(type === 'multi-group') {

    let multiGroupComponent = (
      <DotChip color='public' name='Multiple groups' size={size} />
    );

    if (!onClick) {
      multiGroupComponent = (
        <Link to={{ pathname: '/group', state: { name: 'Multiple groups', color: theme.palette.primary.light, members: context.people } }} >
          {multiGroupComponent}
        </Link>
      );
    }

    return multiGroupComponent;

  } else if(type === 'group' && group?.name === '') {

    const members = group.members.filter(member => member.person && member.person._id !== from._id);
    let groupComponent = null;

    const memberNames = members.map(member => <ContactName key={member.person._id} contact={member.person} type='username' prefix='' />);
    const memberNamesJoined = memberNames.reduce((prev, curr) => prev.length === 0 ? [curr] : [...prev, ', ', curr], [] as (ReactNode | string)[]);

    if(size === 'small') {
      groupComponent = (
        <Box sx={{ lineHeight: '1.3em', display: 'flex', alignItems: 'center', ...restSx }} {...rest}>
          <Typography variant='body2' noWrap sx={{ ...typography }}>
            {memberNamesJoined}
          </Typography>
        </Box>
      );
    } else {
      groupComponent = (
        <Box sx={{ lineHeight: '1.3em', display: 'flex', alignItems: 'center', ...restSx }} {...rest}>
          <AvatarStack contacts={members.map(member => member.person).filter(Boolean)} size='small' borderColor={color} />
          <Typography variant='h3' sx={{ marginLeft: '8px', ...typography }} noWrap>
            {memberNamesJoined}
          </Typography>
        </Box>
      );
    }

    if (!onClick) {
      if(members.length === 1) {
        groupComponent = (
          <ProfileLink contactId={members[0].person._id}>
            {groupComponent}
          </ProfileLink>
        );
      } else {
        groupComponent = (
          <Link to={`/groups/${group._id}`} >
            {groupComponent}
          </Link>
        );
      }
    }

    return groupComponent;

  } else if(type === 'group' && group?.name) {

    const name = group?.name;

    let groupComponent = (
      <DotChip color={group.color} name={name} size={size} sx={{ dot: sx, typography: sx?.typography }} />
    );

    if (!onClick) {
      groupComponent = (
        <Link to={`/groups/${group._id}`} >
          {groupComponent}
        </Link>
      );
    }

    return (
      <Box sx={{ lineHeight: '1.3em', ...restSx }} {...rest}>
        {groupComponent}
      </Box>
    );

  } else if(type === 'public') {

    if(!onClick) {
      onClick = () => history.push(`/profile-id/${from._id}`);
    }

    return (
      <DotChip
        color={theme.palette.secondary.light}
        borderColor={theme.palette.secondary.light}
        name='Friends you follow'
        size={size}
        onClick={onClick}
        sx={{ dot: sx, typography: sx?.typography }}
        icon={<Globe
          fill={theme.palette.secondary.main}
          style={{
            stroke: 'none',
            width: size === 'small' ? '16px' : '18px',
            height: size === 'small' ? '16px' : '18px'
          }}
        />}
      />
    );

  } else if(type === 'public-list') {

    if(!onClick) {
      onClick = () => history.push(`/profile-id/${from._id}`);
    }

    return (
      <DotChip
        color={theme.palette.secondary.light}
        borderColor={theme.palette.secondary.light}
        name='Friends you follow'
        size={size}
        onClick={onClick}
        sx={{ dot: sx, typography: sx?.typography }}
        icon={<Globe
          fill={theme.palette.secondary.main}
          style={{
            stroke: 'none',
            width: size === 'small' ? '16px' : '18px',
            height: size === 'small' ? '16px' : '18px'
          }}
        />}
      />
    );

  } else if(type === 'self') {

    return <Typography variant={size === 'small' ? 'body2' : 'h3'}>Saved</Typography>;

  } else {

    console.error(`Context type ${JSON.stringify(type)} not implemented.`);
    return (<div {...rest}></div>);

  }
}
