import PropTypes from 'prop-types';
import { Link as MuiLink, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContact } from '../hooks/use-contact';


export function Mention({ mention, addLink=true }) {
  const { data, loading, error } = useContact(mention.person?._id, { skip: !mention.person?._id });

  if(loading) {
    return <>{mention.callout}</>;
  }

  if(error) {
    console.error('Could not resolve mention contact', { mention, error });
    return <>{mention.callout}</>;
  }

  if(!data?.contact?.username) {
    return <>{mention.callout}</>;
  }

  const sx = {
    color: addLink ? 'primary.main' : 'text.primary',
  };

  if (addLink) {
    return (
      <MuiLink sx={sx} component={Link} to={`/@${data.contact.username}`} underline="none">
        @{data.contact.username}
      </MuiLink>
    );
  } else {
    return (
      <Box sx={sx} component="span">
        @{data.contact.username}
      </Box>
    );
  }
}

Mention.propTypes = {
  mention: PropTypes.object,
  addLink: PropTypes.bool,
};
